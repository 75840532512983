import { ChainId } from '@usedapp/core'
import { FallbackProvider, JsonRpcProvider } from '@ethersproject/providers'
import { BigNumber, FixedNumber, Signer } from 'ethers'
import { useEffect, useMemo, useState } from 'react'
import {
  AssetVault,
  CollateralTokenPairing,
  VaultHolding,
  VaultPageParams,
  VaultStatistics,
} from '../models/vault'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useChainConfig, useConfig } from '../providers/config'
import {
  DocumentType,
  getQueryDocument,
  getQueryVariables,
} from '../managers/subgraphManager'
import {
  useBucketedCurrentTime,
  useGraphQuery,
  useIdleVaultRates,
  useSentioData,
} from '../data/hooks/helper-hooks'
import { PageVaultQuery, PageVaultQueryVariables } from '../gql/vaults/graphql'
import { useGlobalRefresher } from '../providers/refresher'
import { useVault } from '../data/hooks/use-vault'
import { useVaultMutations } from '../data/hooks/use-vault-mutations'
import { bigToFixedNumber } from '../helpers/conversions'
import { Address, Currency } from '../data/model'
import { add, divide, fixedCompare, multiply, subtract } from '../helpers/math'
import { calculateCurrentYield, mapSentioAPIResults } from '../helpers/utils'
import { useTokenApprove } from '../data/hooks/use-token-approve'
import { useAllowances } from '../data/hooks/use-allowances'
import { useTokenWrapper } from '../data/hooks/use-token-wrapper'
import { useNativeTokenBalance } from '../data/hooks/use-native-token-balance'
import { useBalances } from '../data/hooks/use-balances'
import { useCurrencies } from '../data/hooks/use-currencies'
import { usePrices } from '../data/hooks/use-prices'
import {
  TermRepoTokenHoldingsQuery,
  TermRepoTokenHoldingsQueryVariables,
  VaultOpenOffersQuery,
  VaultOpenOffersQueryVariables,
} from '../gql/graphql'
import { useVaultsMappings } from '../data/hooks/use-vaults-mappings'
import { useVaultHoldings } from '../data/hooks/use-vault-holdings'
import { useGovernance } from '../data/hooks/use-governance'
import { ONBOARDED_ASSETS } from '../helpers/constants'

export function useVaultPage(
  activeNetwork: ChainId | undefined,
  account: string | undefined,
  provider: JsonRpcProvider | FallbackProvider | undefined,
  signer: Signer | undefined,
  onConnect: () => void,
  onCheckActiveNetwork: (
    chainId?: ChainId,
    chainName?: string
  ) => Promise<boolean>,
  onKytCheck: () => Promise<boolean>
) {
  const config = useConfig()
  const { vaultAddress, chainId } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const { state } = location || {}

  if (vaultAddress === undefined) {
    throw new Error(
      'Vault component may only be used within a route with id param'
    )
  }

  const vaultAddressLower = vaultAddress.toLowerCase()

  if (chainId === undefined) {
    throw new Error(
      'Vault component may only be used within a route with chain param'
    )
  }

  const parsedChainId = Number(chainId)
  if (isNaN(parsedChainId)) {
    throw new Error(`Invalid value provided for ${parsedChainId}`)
  }

  const chainData = useChainConfig(parsedChainId)

  if (!chainData) {
    throw new Error(`Invalid chain id ${parsedChainId} - no chain config found`)
  }

  const { slow: autoRefresher } = useGlobalRefresher()

  // Vault subgraph query
  // #region

  const [vaultsSubgraphQuery, setVaultsSubgraphQuery] = useState<any>({})
  useEffect(() => {
    const subgraphVersion = chainData.getSubgraphVersion()
    const queryDoc = getQueryDocument(subgraphVersion, DocumentType.PAGE_VAULT)
    const queryVariables = getQueryVariables({
      subgraphVersion,
      docType: DocumentType.PAGE_VAULT,
      variables: {
        id: vaultAddressLower,
      },
    })
    const query = {
      chainId: parsedChainId,
      url: chainData.vaultsSubgraphUrl,
      query: queryDoc,
      variables: queryVariables,
    }
    setVaultsSubgraphQuery(query)
  }, [account, vaultAddressLower, chainData, parsedChainId])

  // Query data from subgraph for page
  // TODO: wire up fetching, error flags
  const {
    results: vaultSubgraphData,
    fetching,
    error,
    refresh: readFromVaultsSubgraph,
  } = useGraphQuery<PageVaultQuery, PageVaultQueryVariables>(
    vaultsSubgraphQuery
  )

  useEffect(() => {
    readFromVaultsSubgraph()
  }, [readFromVaultsSubgraph, autoRefresher])

  const {
    aragonDaoUrl,
    pendingGovernance,
    pendingExecution,
    hasExpiredTransactions,
  } = useGovernance(
    chainId,
    vaultAddressLower,
    vaultSubgraphData?.termVaultStrategy,
    provider
  )

  // #endregion

  // Main subgraph query - auction open offers
  // #region
  const currentTime = useBucketedCurrentTime(15)
  const [auctionOffersSubgraphQuery, setAuctionOffersSubgraphQuery] =
    useState<any>({})
  useEffect(() => {
    const subgraphVersion = chainData.getSubgraphVersion()
    const queryDoc = getQueryDocument(
      subgraphVersion,
      DocumentType.VAULT_OPEN_OFFERS
    )
    const queryVariables = getQueryVariables({
      subgraphVersion,
      docType: DocumentType.VAULT_OPEN_OFFERS,
      variables: {
        vaultAddresses: [vaultAddressLower],
        currentTime: currentTime.unix(),
      },
    })
    const query = {
      chainId: parsedChainId,
      url: chainData.subgraphUrl,
      query: queryDoc,
      variables: queryVariables,
    }
    setAuctionOffersSubgraphQuery(query)
  }, [account, vaultAddressLower, chainData, parsedChainId, currentTime])

  const {
    results: auctionOffersSubgraphData,
    // fetching: auctionOffersFetching,
    // error: auctionOffersError,
    refresh: readFromAuctionOffersSubgraph,
  } = useGraphQuery<VaultOpenOffersQuery, VaultOpenOffersQueryVariables>(
    auctionOffersSubgraphQuery
  )

  useEffect(() => {
    readFromAuctionOffersSubgraph()
  }, [readFromAuctionOffersSubgraph, autoRefresher])

  const vaultOpenAuctionOffersWithoutPrice: VaultHolding[] = useMemo(() => {
    if (!auctionOffersSubgraphData) {
      return []
    }

    // Group by termRepoTokenMeta.id
    const groupedOffers = auctionOffersSubgraphData?.termOffers?.reduce(
      (acc, offer) => {
        const repoTokenId = offer.auction.term.termRepoTokenMeta?.id ?? ''

        if (!acc[repoTokenId]) {
          acc[repoTokenId] = {
            chainId,
            isOpenOffer: true,
            repoToken: repoTokenId,
            repoTokenCurrency: {
              address: repoTokenId,
              symbol: offer.auction.term.termRepoTokenMeta?.symbol ?? '',
              decimals: offer.auction.term.termRepoTokenMeta?.decimals ?? 18,
              isRepoToken: true,
            },
            presentValue: FixedNumber.fromString('0', 18),
            faceValue: FixedNumber.fromString('0', 18),
            presentValueUsd: FixedNumber.fromString('0', 18),
            repurchaseTimestamp: offer.auction.term.repurchaseTimestamp,
            redemptionTimestamp: offer.auction.term.redemptionTimestamp,
            collateralTokens:
              offer.auction.term.collateralTokensMeta?.map(
                (collateralTokenMeta) => {
                  return {
                    address: collateralTokenMeta.id,
                    symbol: collateralTokenMeta.symbol,
                    decimals: collateralTokenMeta.decimals,
                    isRepoToken: false,
                  } as Currency
                }
              ) ?? [],
            auctionClearingPrice: FixedNumber.fromString('0', 18),
            distributionRatio: FixedNumber.fromString('0', 18),
          }
        }

        // Aggregate presentValue and faceValue
        acc[repoTokenId].presentValue = add(
          acc[repoTokenId].presentValue,
          bigToFixedNumber(
            offer.amount,
            offer.auction.term.purchaseTokenMeta?.decimals
          )
        )
        acc[repoTokenId].faceValue = add(
          acc[repoTokenId].faceValue,
          bigToFixedNumber(
            offer.amount,
            offer.auction.term.purchaseTokenMeta?.decimals
          )
        )

        return acc
      },
      {} as { [repoTokenAddress: Address]: VaultHolding }
    )

    // Convert grouped object to an array
    return Object.values(groupedOffers)

    // return pendingOffers
  }, [auctionOffersSubgraphData, chainId])

  // #endregion

  // TEMP:

  const {
    proposerAddress,
    governorAddress,
    delayModifierAddress,
    assetDecimalsPerStrategy,
    assetDecimals,
  } = useMemo(() => {
    if (!vaultSubgraphData) {
      return {
        proposerAddress: undefined,
        governorAddress: undefined,
        delayModifierAddress: undefined,
        assetDecimalsPerStrategy: undefined,
        assetDecimals: 18,
      }
    } else {
      return {
        proposerAddress:
          vaultSubgraphData?.termVaultStrategy?.proposer?.toLowerCase(),
        governorAddress:
          vaultSubgraphData?.termVaultStrategy?.governor?.toLowerCase(),
        delayModifierAddress: vaultSubgraphData?.termVaultStrategy?.delay ?? '',
        assetDecimalsPerStrategy: {
          [chainId]: {
            [vaultAddressLower]: Number(
              vaultSubgraphData?.termVaultStrategy?.asset?.decimals
            ),
          },
        },
        assetDecimals:
          Number(vaultSubgraphData?.termVaultStrategy?.asset?.decimals) ?? 18,
      }
    }
  }, [chainId, vaultAddressLower, vaultSubgraphData])

  const { data: sentioData } = useSentioData([chainId], vaultAddressLower)
  const mappedSentioResults = useMemo(() => {
    if (!sentioData || !assetDecimalsPerStrategy) return {}
    return mapSentioAPIResults(sentioData, assetDecimalsPerStrategy)
  }, [sentioData, assetDecimalsPerStrategy])

  const {
    weightedAverageLength,
    liquidReserveRatio,
    currentPricePerShare,
    convertToAssetsRatio,
    totalAssetValue,
    performanceFee,
    isPaused,
    isShutdown,
    isGovernor,
    pendingManagementAddress,
    availableDepositLimit,
    availableWithdrawLimit,
    repoTokenHoldings: repoTokenHoldingsWithoutValue,
    totalLiquidBalance,
  } = useVault(
    account,
    vaultAddressLower,
    chainId,
    proposerAddress,
    assetDecimals,
    provider
  ) || {}

  // TODO: temporary for debugging, remove later
  useEffect(() => {
    console.log('pending management address: %o', pendingManagementAddress)
  }, [pendingManagementAddress])

  const {
    deposit,
    withdraw,
    setTermController,
    setCollateralTokenParams,
    setDiscountRateAdapter,
    setDiscountRateMarkup,
    setRepoTokenBlacklist,
    setRequiredReserveRatio,
    setRepoTokenConcentrationLimit,
    setTimeToMaturityThreshold,
    pauseStrategy,
    unpauseStrategy,
    pauseDeposit,
    unpauseDeposit,
    // shutdownStrategy,
    acceptManagement,
    executeVaultParameterUpdate,
    skipExpiredTransactions,
  } = useVaultMutations(
    account,
    vaultAddressLower,
    chainId,
    proposerAddress,
    delayModifierAddress,
    signer,
    provider,
    readFromVaultsSubgraph
  )

  const [subgraphQuery, setSubgraphQuery] = useState<any>({})
  useEffect(() => {
    const subgraphVersion = chainData.getSubgraphVersion()
    const queryDoc = getQueryDocument(
      subgraphVersion,
      DocumentType.TERM_REPO_TOKEN_HOLDINGS
    )
    const queryVariables = getQueryVariables({
      subgraphVersion,
      docType: DocumentType.TERM_REPO_TOKEN_HOLDINGS,
      variables: {
        termRepoTokens:
          repoTokenHoldingsWithoutValue?.map((holding) => holding.repoToken) ??
          [],
      },
    })
    const query = {
      chainId: parsedChainId,
      url: chainData.subgraphUrl,
      query: queryDoc,
      variables: queryVariables,
    }
    setSubgraphQuery(query)
  }, [
    account,
    vaultAddressLower,
    chainData,
    parsedChainId,
    repoTokenHoldingsWithoutValue,
  ])

  const {
    results: subgraphData,
    // fetching: subgraphFetching,
    // error: subgraphError,
    refresh: readFromSubgraph,
  } = useGraphQuery<
    TermRepoTokenHoldingsQuery,
    TermRepoTokenHoldingsQueryVariables
  >(subgraphQuery)

  const vaultsMapping = useVaultsMappings()

  const mappedVaultData = useMemo(() => {
    let vaultStatistics: VaultStatistics
    let vaultAssetCurrency: Currency
    let currentDiscountRateMarkup: string
    let assetVaultAddress: Address
    let currentTermController: Address
    let currentDiscountRateAdapter: Address
    let isDepositPaused: boolean = false

    let collateralTokensPairing: CollateralTokenPairing[] = []

    if (
      vaultSubgraphData?.termVaultStrategy &&
      liquidReserveRatio &&
      currentPricePerShare &&
      weightedAverageLength !== undefined &&
      totalAssetValue &&
      performanceFee &&
      mappedSentioResults &&
      vaultsMapping
    ) {
      const isManagerCalculated =
        account?.toLowerCase() === vaultSubgraphData.termVaultStrategy.manager

      const isGovernorMismatchCalculated =
        !vaultSubgraphData.termVaultStrategy.ownerless ||
        !vaultSubgraphData.termVaultStrategy.governor ||
        vaultSubgraphData.termVaultStrategy.governor.toLowerCase() !==
          vaultSubgraphData.termVaultStrategy.ownerless.toLowerCase()

      const isPendingManagementCalculated = !!(
        pendingManagementAddress &&
        account &&
        account.toLowerCase() === pendingManagementAddress.toLowerCase()
      )

      const currentPricePerShareByStrategy = {
        [chainId]: { [vaultAddressLower]: currentPricePerShare },
      }

      const currentYield = calculateCurrentYield(
        currentPricePerShareByStrategy,
        mappedSentioResults
      )

      vaultAssetCurrency = {
        address: vaultSubgraphData.termVaultStrategy.asset.id,
        symbol: vaultSubgraphData.termVaultStrategy.asset.symbol,
        decimals: vaultSubgraphData.termVaultStrategy.asset.decimals,
        isRepoToken: false,
      }

      isDepositPaused = !!vaultSubgraphData.termVaultStrategy.depositPaused

      assetVaultAddress = vaultSubgraphData.termVaultStrategy.assetVault

      vaultStatistics = {
        currentYield: currentYield[chainId][vaultAddressLower] ?? undefined,
        totalAssetValue: totalAssetValue,
        pricePerShare: currentPricePerShare,
        convertToAssetsRatio:
          convertToAssetsRatio ?? FixedNumber.fromString('1', `fixed128x18`),
        yourValue: FixedNumber.fromString('0'),
        yourVaultAssetCurrency: FixedNumber.fromString('0'),
        vaultCurator:
          vaultsMapping?.vaultDetails?.[chainId]?.[vaultAddressLower]
            ?.curatorName ?? 'Unknown',
        curatorIcon:
          vaultsMapping?.vaultDetails?.[chainId]?.[vaultAddressLower]?.icon,
        vaultName:
          vaultsMapping?.vaultDetails?.[chainId]?.[vaultAddressLower]
            ?.vaultName ?? 'Unknown',
        vaultType: 'Yearn V3',
        WAL: weightedAverageLength ?? 0,
        WALCap:
          vaultSubgraphData.termVaultStrategy.timeToMaturityThreshold / 86400,
        liquidityReserve: liquidReserveRatio,
        requiredReserve: bigToFixedNumber(
          vaultSubgraphData.termVaultStrategy.requiredReserveRatio,
          18
        ),
        concentrationCap: bigToFixedNumber(
          vaultSubgraphData.termVaultStrategy.repoTokenConcentrationLimit,
          18
        ),
        performanceFee: divide(
          performanceFee,
          FixedNumber.fromString('100', 18)
        ),
        managementFee: FixedNumber.fromString('0'),
      }

      currentDiscountRateMarkup =
        multiply(
          bigToFixedNumber(
            vaultSubgraphData.termVaultStrategy.discountRateMarkup,
            18
          ),
          FixedNumber.fromString('100')
        ).toString() ?? ''
      currentTermController = vaultSubgraphData.termVaultStrategy.termController
      currentDiscountRateAdapter =
        vaultSubgraphData.termVaultStrategy.discountRateAdapter

      // map collateral tokens and remove entries with 0 minMaintenanceMargin
      collateralTokensPairing =
        vaultSubgraphData.termVaultStrategy.minCollateralRatios
          .map((minCollateralRatio) => {
            return {
              address: minCollateralRatio.collateralToken,
              symbol:
                vaultSubgraphData.termVaultStrategy?.collateralTokens.find(
                  (cT) => cT.id === minCollateralRatio.collateralToken
                )?.symbol ?? '',
              minMaintenanceMargin: bigToFixedNumber(
                minCollateralRatio.ratio,
                18
              ),
            }
          })
          .filter((collateralTokenPairing) =>
            fixedCompare(
              collateralTokenPairing.minMaintenanceMargin,
              'gt',
              FixedNumber.fromString('0')
            )
          )
          .sort((a, b) => {
            return a.symbol.localeCompare(b.symbol)
          })

      return {
        vaultAssetCurrency,
        vaultStatistics,
        currentDiscountRateMarkup,
        currentTermController,
        assetVaultAddress,
        isDepositPaused,
        currentDiscountRateAdapter,
        collateralTokensPairing,
        isManager: isManagerCalculated,
        isGovernorMismatch: isGovernorMismatchCalculated,
        isPendingManagement: isPendingManagementCalculated,
      }
    } else {
      // console.log('vault.tsx: something undefined so returning')
      // console.log('vault.tsx: vaultSubgraphData: %o', vaultSubgraphData)
      // console.log('vault.tsx: liquidReserveRatio: %o', liquidReserveRatio)
      // console.log('vault.tsx: currentPricePerShare: %o', currentPricePerShare)
      // console.log('vault.tsx: weightedAverageLength: %o', weightedAverageLength)
      // console.log('vault.tsx: totalAssetValue: %o', totalAssetValue)
      // console.log('vault.tsx: performanceFee: %o', performanceFee)
      // console.log('vault.tsx: mappedSentioResults: %o', mappedSentioResults)
      // console.log('vault.tsx: repoTokenHoldingsWithValue: %o', repoTokenHoldingsWithValue)
      // console.log('vault.tsx: vaultsMapping: %o', vaultsMapping)
      return undefined
    }
  }, [
    convertToAssetsRatio,
    vaultSubgraphData?.termVaultStrategy,
    liquidReserveRatio,
    currentPricePerShare,
    weightedAverageLength,
    pendingManagementAddress,
    totalAssetValue,
    performanceFee,
    mappedSentioResults,
    vaultsMapping,
    account,
    chainId,
    vaultAddressLower,
  ])

  const vaultAssetApprove = useTokenApprove(
    signer,
    mappedVaultData?.vaultAssetCurrency,
    parsedChainId
  )

  const tokenAllowances = useMemo(() => {
    if (
      !account ||
      !vaultAddressLower ||
      !mappedVaultData?.vaultAssetCurrency
    ) {
      return undefined
    }
    const allowances = [
      {
        token: mappedVaultData.vaultAssetCurrency.address,
        spender: vaultAddressLower,
        owner: account,
        termId: 'x',
      },
    ]

    return {
      [chainId]: allowances,
    }
  }, [account, vaultAddressLower, mappedVaultData?.vaultAssetCurrency, chainId])
  const allowances = useAllowances(tokenAllowances, provider)

  const vaultAssetAllowance = useMemo(() => {
    return allowances?.[chainId]?.['x']?.[0] ?? BigNumber.from(0)
  }, [allowances, chainId])

  const { wrapGasToken: wrapGasTokenCall } = useTokenWrapper(
    chainData.chainId,
    chainData.contracts.wrappedGasToken,
    provider
  )

  const gasTokenCurrency = useMemo(
    () => chainData.nativeCurrency,
    [chainData.nativeCurrency]
  )

  const gasTokenBalance = useNativeTokenBalance(
    account,
    gasTokenCurrency,
    parsedChainId
  )

  const receiptTokenCurrencyInput = useMemo(
    () => ({
      [chainId]: [
        {
          address: vaultAddressLower,
          isRepoToken: false,
          version: '',
        },
      ],
    }),
    [vaultAddressLower, chainId]
  )
  const receiptTokenCurrencyByChain = useCurrencies(
    receiptTokenCurrencyInput,
    provider
  )

  const receiptTokenCurrency = useMemo(() => {
    if (!receiptTokenCurrencyByChain?.[chainId]?.[0]) {
      return undefined
    } else {
      return receiptTokenCurrencyByChain[chainId][0]
    }
  }, [receiptTokenCurrencyByChain, chainId])

  const assetVaultCurrencyInput = useMemo(() => {
    if (!mappedVaultData?.assetVaultAddress) {
      return undefined
    } else {
      return {
        [chainId]: [
          {
            address: mappedVaultData?.assetVaultAddress ?? '',
            isRepoToken: false,
            version: '',
          },
        ],
      }
    }
  }, [mappedVaultData?.assetVaultAddress, chainId])

  const assetVaultCurrencyByChain = useCurrencies(
    assetVaultCurrencyInput,
    provider
  )

  const assetVaultCurrency = useMemo(() => {
    if (!assetVaultCurrencyByChain?.[chainId]?.[0]) {
      return undefined
    } else {
      return assetVaultCurrencyByChain[chainId][0]
    }
  }, [assetVaultCurrencyByChain, chainId])

  const currenciesByChain = useMemo(() => {
    if (!mappedVaultData?.vaultAssetCurrency || !receiptTokenCurrency)
      return undefined
    const currencies = {
      [mappedVaultData.vaultAssetCurrency.address]:
        mappedVaultData?.vaultAssetCurrency,
      [receiptTokenCurrency.address]: receiptTokenCurrency,
    }

    return {
      [chainId]: currencies,
    }
  }, [receiptTokenCurrency, mappedVaultData?.vaultAssetCurrency, chainId])

  const balancesData = useBalances(account, currenciesByChain, provider)

  const [assetBalance, receiptBalance] = useMemo(() => {
    if (!balancesData) {
      return [FixedNumber.fromString('0', 18), FixedNumber.fromString('0', 18)]
    } else {
      return [
        balancesData[chainId]?.[0].balance,
        balancesData[chainId]?.[1].balance,
      ]
    }
  }, [balancesData, chainId])

  const tokenPairForVaultAsset = useMemo(() => {
    if (!currenciesByChain || !mappedVaultData?.vaultAssetCurrency)
      return undefined

    const vaultAsset =
      currenciesByChain?.[chainId]?.[mappedVaultData.vaultAssetCurrency.address]

    if (!vaultAsset) return undefined

    return {
      [chainId]: [
        {
          address: vaultAsset.address,
          decimals: vaultAsset.decimals,
        },
      ],
    }
  }, [currenciesByChain, mappedVaultData?.vaultAssetCurrency, chainId])

  const pricesData = usePrices(tokenPairForVaultAsset, undefined, provider)

  const vaultAssetPriceFN = useMemo(() => {
    if (!pricesData || !pricesData[chainId]?.[0]) {
      return undefined
    } else {
      return bigToFixedNumber(
        pricesData[chainId][0].price,
        pricesData[chainId][0].decimals
      )
    }
  }, [pricesData, chainId])

  const vaultOpenAuctionOffersWithPrice = useMemo(() => {
    if (!vaultOpenAuctionOffersWithoutPrice || !vaultAssetPriceFN) {
      return undefined
    }

    return vaultOpenAuctionOffersWithoutPrice.map((offer) => {
      return {
        ...offer,
        presentValueUsd: multiply(
          offer.presentValue,
          vaultAssetPriceFN,
          vaultAssetPriceFN.format.decimals
        ),
        distributionRatio:
          totalAssetValue && !totalAssetValue.isZero()
            ? divide(offer.presentValue, totalAssetValue)
            : FixedNumber.fromString('0', 18),
      }
    })
  }, [vaultAssetPriceFN, vaultOpenAuctionOffersWithoutPrice, totalAssetValue])

  const totalLiquidBalanceUsd = useMemo(() => {
    if (!totalLiquidBalance || !vaultAssetPriceFN) {
      return undefined
    }

    // include open offers in liquid balance
    let openOffersUsdBalance = FixedNumber.fromString(
      '0',
      vaultAssetPriceFN.format.decimals
    )
    if (vaultOpenAuctionOffersWithPrice) {
      openOffersUsdBalance = vaultOpenAuctionOffersWithPrice.reduce(
        (acc, offer) =>
          add(acc, offer.presentValueUsd, vaultAssetPriceFN.format.decimals),
        FixedNumber.fromString('0', vaultAssetPriceFN.format.decimals)
      )
    }

    const totalLiquidBalanceUsd = multiply(
      totalLiquidBalance,
      vaultAssetPriceFN,
      vaultAssetPriceFN.format.decimals
    )

    return add(
      totalLiquidBalanceUsd,
      openOffersUsdBalance,
      vaultAssetPriceFN.format.decimals
    )
  }, [totalLiquidBalance, vaultOpenAuctionOffersWithPrice, vaultAssetPriceFN])

  const totalLiquidBalanceDistribution = useMemo(() => {
    if (!totalLiquidBalance || !totalAssetValue || totalAssetValue.isZero()) {
      return FixedNumber.fromString('0', `fixed128x18`)
    }

    return divide(totalLiquidBalance, totalAssetValue)
  }, [totalLiquidBalance, totalAssetValue])

  // Get idle vault capital deployed interest rate
  // #region
  const reserveId = useMemo(() => {
    if (
      !vaultsMapping?.vaultDetails?.[chainId]?.[vaultAddressLower]
        ?.idleCapitalDeployed
    ) {
      return undefined
    }
    return [
      vaultsMapping.vaultDetails[chainId][vaultAddressLower]
        .idleCapitalDeployed,
    ]
  }, [vaultsMapping, chainId, vaultAddressLower])
  const mappedIdleVaultRates = useIdleVaultRates(reserveId)
  // #endregion

  // fetch vault asset information
  const assetVault = useMemo(() => {
    if (!assetVaultCurrency || !vaultsMapping?.assetVaults) {
      return undefined
    }
    return {
      currency: assetVaultCurrency,
      mapping: vaultsMapping.assetVaults[chainId]?.[assetVaultCurrency.address],
    } as AssetVault
  }, [assetVaultCurrency, chainId, vaultsMapping?.assetVaults])

  const vaultHoldings = useVaultHoldings(
    chainId,
    vaultAddressLower,
    auctionOffersSubgraphData,
    vaultSubgraphData,
    subgraphData,
    repoTokenHoldingsWithoutValue,
    vaultAssetPriceFN,
    totalAssetValue,
    provider
  )

  const estimatedAPY = useMemo(() => {
    if (
      !vaultHoldings ||
      !vaultHoldings.repoTokenHoldings ||
      !totalLiquidBalanceDistribution ||
      !reserveId?.[0] ||
      !mappedIdleVaultRates ||
      !mappedIdleVaultRates?.[reserveId[0]] ||
      !performanceFee
    ) {
      return undefined
    }

    const repoTokenHoldingsAPY = vaultHoldings.repoTokenHoldings.reduce(
      (acc, offer) => {
        const rate = multiply(
          offer.distributionRatio,
          offer.auctionClearingPrice
        )
        return add(acc, rate)
      },
      FixedNumber.fromString('0', `fixed128x18`)
    )

    // include open offers in liquid balance for APY estimation
    const openOffersDistribution =
      vaultHoldings?.openAuctionOffers?.reduce(
        (acc, offer) => {
          return add(acc, offer.distributionRatio)
        },
        FixedNumber.fromString('0', `fixed128x18`)
      ) ?? FixedNumber.fromString('0', `fixed128x18`)

    const idleVaultSupplyFN = divide(
      FixedNumber.fromString(
        mappedIdleVaultRates[reserveId?.[0]] ?? '0',
        `fixed128x18`
      ),
      FixedNumber.fromString('100', `fixed128x18`)
    )

    const idleVaultRatesAPY = multiply(
      idleVaultSupplyFN,
      add(totalLiquidBalanceDistribution, openOffersDistribution)
    )

    const totalAPY = multiply(
      add(repoTokenHoldingsAPY, idleVaultRatesAPY),
      subtract(FixedNumber.fromString('100', `fixed128x18`), performanceFee)
    )

    return totalAPY
  }, [
    vaultHoldings,
    reserveId,
    totalLiquidBalanceDistribution,
    mappedIdleVaultRates,
    performanceFee,
  ])

  const supportedCollateralTokens = useMemo(() => {
    if (config.isMainnet) {
      return ONBOARDED_ASSETS[chainId] ?? []
    } else {
      return chainData.testnetTokens ?? []
    }
  }, [config.isMainnet, chainId, chainData.testnetTokens])

  const params = useMemo(
    () =>
      ({
        account,
        vaultStatistics: {
          ...mappedVaultData?.vaultStatistics,
          currentYield: estimatedAPY,
        },
        idleVaultRate: reserveId?.[0]
          ? mappedIdleVaultRates?.[reserveId[0]]
          : undefined,
        largestHolding: vaultHoldings?.largestHolding,
        collateralTokens: mappedVaultData?.collateralTokensPairing,
        isDepositPaused: mappedVaultData?.isDepositPaused,
        isPendingManagement: mappedVaultData?.isPendingManagement,
        isVaultPaused: !!isPaused,
        isVaultShutdown: !!isShutdown,
        isVaultGovernor: isGovernor,
        isVaultManager: mappedVaultData?.isManager,
        isGovernorMismatch: mappedVaultData?.isGovernorMismatch,
        currentTermController: mappedVaultData?.currentTermController,
        currentDiscountRateMarkup: mappedVaultData?.currentDiscountRateMarkup,
        currentDiscountRateAdapter: mappedVaultData?.currentDiscountRateAdapter,
        vaultAssetCurrency: mappedVaultData?.vaultAssetCurrency,
        vaultAssetPrice: vaultAssetPriceFN,
        vaultAssetBalance: assetBalance,
        vaultAssetAllowance,
        vaultReceiptCurrency: receiptTokenCurrency,
        vaultReceiptBalance: receiptBalance,
        supportedCollateralTokens,
        assetVault,
        gasTokenCurrency,
        gasTokenBalance:
          gasTokenBalance?.balance ?? FixedNumber.fromString('0', 18),
        availableDepositLimit:
          availableDepositLimit ??
          FixedNumber.fromString(
            '0',
            `fixed128x${mappedVaultData?.vaultAssetCurrency?.decimals ?? 18}`
          ),
        availableWithdrawLimit:
          availableWithdrawLimit ??
          FixedNumber.fromString(
            '0',
            `fixed128x${mappedVaultData?.vaultAssetCurrency?.decimals ?? 18}`
          ),
        totalLiquidBalance,
        totalLiquidBalanceDistribution,
        totalLiquidBalanceUsd,
        openAuctionOffers: vaultHoldings ? vaultHoldings.openAuctionOffers : [],
        repoTokenHoldings: vaultHoldings ? vaultHoldings.repoTokenHoldings : [],
        onConnect,
        onCheckActiveNetwork,
        onKytCheck,
        onDeposit: async (amount: string) => {
          if (!account) {
            throw new Error('Account is undefined')
          }
          await onCheckActiveNetwork(chainData.chainId, chainData.chainName)
          await deposit(
            chainId,
            FixedNumber.fromString(amount, `fixed128x${assetDecimals}`)
          )
        },
        onWithdraw: async (amount: string, maxLoss?: number) => {
          if (!account) {
            throw new Error('Account is undefined')
          }
          await onCheckActiveNetwork(chainData.chainId, chainData.chainName)
          await withdraw(
            chainId,
            FixedNumber.fromString(amount, `fixed128x${assetDecimals}`),
            maxLoss ? FixedNumber.fromString(maxLoss.toString(), 18) : undefined
          )
        },
        onSetTermController: async (address: Address) => {
          await onCheckActiveNetwork(chainData.chainId, chainData.chainName)
          await setTermController(chainId, address)
        },
        onSetCollateralTokenParams: async (
          collateralToken: Address,
          minCollateralRatio: FixedNumber
        ) => {
          await onCheckActiveNetwork(chainData.chainId, chainData.chainName)
          const minCollateralRatioValue = divide(
            minCollateralRatio,
            FixedNumber.fromString('100', 18)
          )
          await setCollateralTokenParams(
            chainId,
            collateralToken,
            minCollateralRatioValue
          )
        },
        onSetDiscountRateAdapter: async (address: Address) => {
          await onCheckActiveNetwork(chainData.chainId, chainData.chainName)
          await setDiscountRateAdapter(chainId, address)
        },
        onSetDiscountRateMarkup: async (markup: string) => {
          await onCheckActiveNetwork(chainData.chainId, chainData.chainName)
          await setDiscountRateMarkup(
            chainId,
            FixedNumber.fromString(markup, 18)
          )
        },
        onSetRepoTokenBlacklist: async (address: Address) => {
          await onCheckActiveNetwork(chainData.chainId, chainData.chainName)
          await setRepoTokenBlacklist(chainId, address, true)
        },
        onSetRequiredReserveRatio: async (ratio: string) => {
          await onCheckActiveNetwork(chainData.chainId, chainData.chainName)
          const ratioValue = divide(
            FixedNumber.fromString(ratio, 18),
            FixedNumber.fromString('100', 18)
          )
          await setRequiredReserveRatio(chainId, ratioValue)
        },
        onSetRepoTokenConcentrationLimit: async (limit: string) => {
          await onCheckActiveNetwork(chainData.chainId, chainData.chainName)
          const limitValue = divide(
            FixedNumber.fromString(limit, 18),
            FixedNumber.fromString('100', 18)
          )
          await setRepoTokenConcentrationLimit(chainId, limitValue)
        },
        onSetTimeToMaturityThreshold: async (threshold: string) => {
          await onCheckActiveNetwork(chainData.chainId, chainData.chainName)
          await setTimeToMaturityThreshold(chainId, Number(threshold))
        },
        onPauseStrategy: async () => {
          await onCheckActiveNetwork(chainData.chainId, chainData.chainName)
          await pauseStrategy(chainId)
        },
        onUnpauseStrategy: async () => {
          await onCheckActiveNetwork(chainData.chainId, chainData.chainName)
          await unpauseStrategy(chainId)
        },
        onPauseDeposit: async () => {
          await onCheckActiveNetwork(chainData.chainId, chainData.chainName)
          await pauseDeposit(chainId)
        },
        onUnpauseDeposit: async () => {
          await onCheckActiveNetwork(chainData.chainId, chainData.chainName)
          await unpauseDeposit(chainId)
        },
        onAcceptManagement: async () => {
          await onCheckActiveNetwork(chainData.chainId, chainData.chainName)
          await acceptManagement(chainId)
        },
        onVaultAssetApprove: async (amount?: string) => {
          await onCheckActiveNetwork(chainData.chainId, chainData.chainName)
          await vaultAssetApprove(
            vaultAddressLower,
            amount || config.approveAmount
          )
        },
        onWrapGasToken: async (amount: BigNumber) => {
          await onCheckActiveNetwork(chainData.chainId, chainData.chainName)
          await wrapGasTokenCall(amount)
        },
        onViewMetaVault: () =>
          navigate(`/vaults/meta/${state?.metaVault.address}/${chainId}`),
        onViewVaults: () => navigate(`/vaults`),
        onExecuteVaultParameterUpdate: async (
          nonce: number,
          calldata: string
        ) => {
          await onCheckActiveNetwork(chainData.chainId, chainData.chainName)
          await executeVaultParameterUpdate(chainId, nonce, calldata)
        },
        onSkipExpiredTransactions: async () => {
          await onCheckActiveNetwork(chainData.chainId, chainData.chainName)
          await skipExpiredTransactions(chainId)
        },
        metaVaultSelected: state?.metaVault?.vaultName || undefined,
        vaultGovernance: {
          url: aragonDaoUrl,
          pendingGovernance,
          pendingExecution,
          hasExpiredTransactions,
        },
      }) as VaultPageParams,
    [
      account,
      chainData,
      config.approveAmount,
      estimatedAPY,
      mappedVaultData,
      mappedIdleVaultRates,
      reserveId,
      isPaused,
      isShutdown,
      isGovernor,
      chainId,
      assetDecimals,
      vaultAssetAllowance,
      gasTokenCurrency,
      gasTokenBalance,
      vaultAddressLower,
      assetBalance,
      vaultHoldings,
      vaultAssetPriceFN,
      assetVault,
      receiptBalance,
      receiptTokenCurrency,
      availableDepositLimit,
      availableWithdrawLimit,
      totalLiquidBalance,
      totalLiquidBalanceDistribution,
      totalLiquidBalanceUsd,
      pendingGovernance,
      pendingExecution,
      aragonDaoUrl,
      hasExpiredTransactions,
      supportedCollateralTokens,
      wrapGasTokenCall,
      vaultAssetApprove,
      deposit,
      withdraw,
      setTermController,
      setCollateralTokenParams,
      setDiscountRateAdapter,
      setDiscountRateMarkup,
      setRepoTokenBlacklist,
      setRequiredReserveRatio,
      setRepoTokenConcentrationLimit,
      setTimeToMaturityThreshold,
      pauseStrategy,
      unpauseStrategy,
      pauseDeposit,
      unpauseDeposit,
      acceptManagement,
      executeVaultParameterUpdate,
      skipExpiredTransactions,
      onConnect,
      onKytCheck,
      onCheckActiveNetwork,
      state?.metaVault,
      navigate,
    ]
  )
  return params
}
