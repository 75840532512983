import { useCallback } from 'react'
import ReactGA from 'react-ga4'
import { SourceTrack } from './model'
import { captureException } from '@sentry/react'

declare global {
  interface Window {
    safary?: {
      track: (args: {
        eventType: string
        eventName: string
        parameters: { [key: string]: string }
      }) => void
    }
  }
}

export function useAnalytics() {
  const trackEvent = useCallback(
    (
      eventType: string,
      eventName: string,
      parameters: { [key: string]: string },
      typeTrack?: SourceTrack
    ) => {
      // Default to tracking on all sources if no specific source is provided
      const trackGA = !typeTrack || typeTrack === SourceTrack.reactGA
      const trackSafary = !typeTrack || typeTrack === SourceTrack.safary

      if (trackGA) {
        try {
          ReactGA.event(eventName, {
            category: eventType,
            action: eventName,
            ...parameters,
          })
        } catch (error) {
          console.error('Failed to track GA event:', error)
          captureException(error)
        }
      }

      if (trackSafary && window.safary) {
        try {
          window.safary.track({
            eventType,
            eventName,
            parameters,
          })
        } catch (error) {
          console.error('Failed to track safary event:', error)
          captureException(error)
        }
      }
    },
    []
  )

  return { trackEvent }
}
