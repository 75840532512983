import { Box, Button, Flex, Grid, Skeleton, Text } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useMemo, useState } from 'react'
import { VStack } from '../../../../components/elements/Stack'
import Tooltip from '../../../../components/elements/Tooltip'
import { PointsBreakdownProps, formatNumber } from '../../utils'
import EarningsCategory from './EarningsCategory'
import EarningsChart from './EarningsChart'

/**
 * Renders the PointsBreakdown component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.earningsData - The earnings data used to calculate the total term points.
 * @returns {JSX.Element} The rendered PointsBreakdown component.
 */
export default function PointsBreakdown({
  earningsData,
  season,
  totalPoints,
  lastUpdated,
  isLoading,
}: PointsBreakdownProps & { isLoading?: boolean }) {
  const formattedTotalPoints = formatNumber(totalPoints, true, undefined, true)
  const lastUpdatedRelative = useMemo(() => {
    if (lastUpdated) {
      return dayjs(lastUpdated * 1000).fromNow()
    }
  }, [lastUpdated])

  const [minColWidth, setMinColWidth] = useState(0)

  const handleColumnWidth = (width: number) => {
    setMinColWidth((oldWidth) => Math.max(oldWidth, width))
  }

  return (
    <Flex
      flexDir="row"
      py="26px"
      px="30px"
      background="blue.10"
      borderRadius="6px"
    >
      <Flex gap={{ base: 2, xl: 5 }} flexDirection="row" h="92px">
        <VStack
          justify="space-between"
          align="start"
          h="100%"
          borderRight="1px solid"
          borderRightColor="gray.3"
          pr={{
            base: totalPoints > 0 ? '21px' : 5,
            '1xl': '42px',
          }}
          mr={{
            base: totalPoints > 0 ? 1.5 : 3,
            '1xl': 3,
          }}
        >
          <Tooltip
            aria-label="total-term-points-tooltip"
            bg="blue.900"
            placement="bottom-start"
            offset={[0, 39]}
            p={0}
            noDelay
            label={
              <VStack align="start">
                <Text variant="body-sm/medium" color="rgba(255, 255, 255, 0.7)">
                  Total Term Points
                </Text>
                <Text variant="body-xl/medium">
                  {totalPoints
                    ? parseFloat(totalPoints.toFixed(2)).toLocaleString()
                    : '0.00'}
                </Text>
              </VStack>
            }
          >
            <Box>
              <VStack justify="space-between" align="start" h="92px">
                <Text
                  variant="body-xs/medium"
                  color="white"
                  whiteSpace="nowrap"
                >
                  Total Term Points
                </Text>
                <Skeleton
                  minHeight="32px"
                  minWidth="106px"
                  isLoaded={!isLoading}
                >
                  <Text variant="body-2xl/bold" color="white" fontSize="32px">
                    {formattedTotalPoints}
                  </Text>
                </Skeleton>
                <Text variant="body-xs/medium" color="white" opacity={0.55}>
                  {lastUpdatedRelative && `Updated ${lastUpdatedRelative}`}
                </Text>
              </VStack>
            </Box>
          </Tooltip>
        </VStack>
        {/* Title */}
        <Flex
          flexDirection="column"
          align="left"
          justify="center"
          w="max-content"
          mr={{
            base: totalPoints > 0 ? 3 : 8,
            '1xl': '50px',
          }}
        >
          <Box>
            <Text variant="body-md/medium" color="white">
              Breakdown <br />
              of earnings
            </Text>
          </Box>
        </Flex>
      </Flex>
      <Grid
        templateColumns="repeat(4, 1fr)"
        gap={{
          base: totalPoints > 0 ? 5 : 8,
          '1xl': '42px',
        }}
      >
        {/* Breakdown */}
        {earningsData.map((data) => (
          <EarningsCategory
            key={data.category}
            category={data.category}
            value={data.value}
            totalPoints={totalPoints}
            borderColor={data.color}
            handleColumnWidth={handleColumnWidth}
            minColWidth={minColWidth}
            isLoading={isLoading}
          />
        ))}
      </Grid>
      {!isLoading ? (
        <>
          {/* Chart */}
          <Flex
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            color="white"
            mx={{
              base: 5,
              '1xl': 10,
            }}
          >
            {totalPoints ? (
              <EarningsChart totalPoints={totalPoints} data={earningsData} />
            ) : undefined}
          </Flex>
          <Flex
            flexDirection="column"
            justifyContent="space-around"
            pt={4}
            pb={2.5}
            ml="auto"
          >
            <Text
              variant="body-sm/medium"
              color="white"
              display={{
                base: totalPoints > 0 ? 'none' : 'block',
                xl: 'block',
              }}
            >
              {season.name} ends in{' '}
              <span
                style={{ color: 'transparent', textShadow: '0 0 6px #fff' }}
              >
                ?? days
              </span>
            </Text>
            <Button h="29px" borderRadius="4px" isDisabled>
              <Text as="span" variant="body-sm/medium">
                Claim{' '}
                <Text
                  as="span"
                  display={{ base: 'none', xl: 'inline-block' }}
                >{`${season.name} points`}</Text>
              </Text>
            </Button>
          </Flex>
        </>
      ) : null}
    </Flex>
  )
}
