import ausdc from '../assets/icons/tokens/AUSDC.png'
import usdc from '../assets/icons/tokens/USDC.svg'
import usdt from '../assets/icons/tokens/USDT.svg'
import dai from '../assets/icons/tokens/DAI.svg'
import sdai from '../assets/icons/tokens/SDAI.svg'
import btc from '../assets/icons/tokens/BTC.svg'
import tbtc from '../assets/icons/tokens/TBTC.svg'
import eth from '../assets/icons/tokens/ETH.svg'
import weth from '../assets/icons/tokens/WETH.svg'
import wsteth from '../assets/icons/tokens/WSTETH.svg'
import reth from '../assets/icons/tokens/RETH.svg'
import rseth from '../assets/icons/tokens/RSETH.svg'
import wbtc from '../assets/icons/tokens/WBTC.svg'
import crv from '../assets/icons/tokens/CRV.svg'
import tt from '../assets/icons/tokens/TT.svg'
import term from '../assets/icons/tokens/TERM.svg'
import matic from '../assets/icons/tokens/MATIC.svg'
import wmatic from '../assets/icons/tokens/WMATIC.svg'
import cbeth from '../assets/icons/tokens/CBETH.svg'
import stbt from '../assets/icons/tokens/STBT.svg'
import weeth from '../assets/icons/tokens/WEETH.svg'
import usde from '../assets/icons/tokens/USDE.svg'
import susde from '../assets/icons/tokens/SUSDE.svg'
import apxETH from '../assets/icons/tokens/APXETH.svg'
import pxETH from '../assets/icons/tokens/PXETH.svg'
import savax from '../assets/icons/tokens/SAVAX.svg'
import wavax from '../assets/icons/tokens/WAVAX.svg'
import ezeth from '../assets/icons/tokens/EZETH.svg'
import pufeth from '../assets/icons/tokens/PUFETH.svg'
import btrfly from '../assets/icons/tokens/BTRFLY.svg'
import sol from '../assets/icons/tokens/SOL.svg'
import unibtc from '../assets/icons/tokens/UNIBTC.svg'
import usd0pp from '../assets/icons/tokens/USD0pp.svg'
import usd0 from '../assets/icons/tokens/USD0.svg'
import btcb from '../assets/icons/tokens/BTC.B.svg'
import lbtc from '../assets/icons/tokens/LBTC.svg'
import deusd from '../assets/icons/tokens/DEUSD.svg'
import tryb from '../assets/icons/tokens/TRYB.svg'
import teth from '../assets/icons/tokens/TETH.svg'
import cbbtc from '../assets/icons/tokens/CBBTC.svg'
import ptsusde from '../assets/icons/tokens/PT-SUSDE.svg'
import ethp from '../assets/icons/tokens/ETHp.svg'
import usds from '../assets/icons/tokens/USDS.svg'
import susds from '../assets/icons/tokens/SUSDS.svg'

import yearnVault from '../assets/icons/tokens/YEARN.svg'
import usdcReceipt from '../assets/icons/tokens/USDCR.svg'
import wethReceipt from '../assets/icons/tokens/WETHR.svg'

import unknown from '../assets/icons/error.svg'

const tokenIcons: {
  [token: string]: string
} = {
  USDC: usdc,
  USDE: usde,
  SUSDE: susde,
  USDT: usdt,
  DAI: dai,
  SDAI: sdai,
  BTC: btc,
  ETH: eth,
  WETH: weth,
  CBETH: cbeth,
  WSTETH: wsteth,
  RETH: reth,
  RSETH: rseth,
  WBTC: wbtc,
  CRV: crv,
  TT: tt,
  TERM: term,
  MATIC: matic,
  YEARN: yearnVault,
  WMATIC: wmatic,
  STBT: stbt,
  WSTBT: stbt,
  WEETH: weeth,
  APXETH: apxETH,
  PXETH: pxETH,
  WAVAX: wavax,
  SAVAX: savax,
  EZETH: ezeth,
  PUFETH: pufeth,
  BTRFLY: btrfly,
  SOL: sol,
  UNIBTC: unibtc,
  'USD0++': usd0pp,
  USD0: usd0,
  TBTC: tbtc,
  'BTC.B': btcb,
  LBTC: lbtc,
  DEUSD: deusd,
  TRYB: tryb,
  TETH: teth,
  CBBTC: cbbtc,
  'PT-SUSDE-27FEB2025': ptsusde,
  'PT-SUSDE-27MAR2025': ptsusde,
  'ETH+': ethp,
  USDS: usds,
  SUSDS: susds,

  // vault receipt token icons
  USDCR: usdcReceipt,
  WETHR: wethReceipt,

  AUSDC: ausdc,

  unknown: unknown,
}

export default tokenIcons
