import { Signer } from 'ethers'
import { Address } from '../data/model'
import { components } from './profile-api'
import { RewardedTweet } from '../socials'
import { WalletNetPosition } from '../pages/Rewards/elements/RewardsSummary/NetPosition'

export type Socials = 'discord' | 'twitter'

export type EligibleRetweets =
  | 'launchTweet'
  | 'walletAnalyzer'
  | 'vaultsAnnouncement'

export enum SocialActions {
  SignUpLinkTwitter = 'sign-up-link-twitter',
  SignUpLinkDiscord = 'sign-up-link-discord',
  SignUpLinkTwitterModal = 'sign-up-link-twitter-modal',
  SignUpLinkDiscordModal = 'sign-up-link-discord-modal',
  LinkTwitter = 'link-twitter',
  LinkDiscord = 'link-discord',
  SignInTwitter = 'auth-twitter',
  SignInDiscord = 'auth-discord',
}

export enum LoginActions {
  SignUpWallet = 'sign-up-wallet',
  SignUpLinkTwitter = 'sign-up-link-twitter',
  SignUpLinkDiscord = 'sign-up-link-discord',
  LinkWallet = 'link-wallet',
  LinkExtraWallet = 'link-extra-wallet',
  LinkTwitter = 'link-twitter',
  LinkDiscord = 'link-discord',
  SignInWallet = 'sign-in-wallet',
  SignInTwitter = 'sign-in-twitter',
  SignInDiscord = 'sign-in-discord',
  LinkMultiSig = 'link-multi-sig',
  SilentLink = 'silent-link',
}

export enum WalletAnalyzerUserTypeEnum {
  UpAndComer = 'up-and-comer',
  DefiVeteran = 'defi-veteran',
  LendingLegend = 'lending-legend',
}

export enum RewardsPageViewEnum {
  NotConnected = 'not-connected',
  InviteCode = 'invite-code',
  SignUp = 'sign-up',
  Dashboard = 'dashboard',
  WalletAnalyzer = 'wallet-analyzer',
}

export enum SignUpPanelEnum {
  Invites = 'invites',
  SignUp = 'sign-up-new-user',
  SignUpPreseason = 'sign-up-preseason',
  SignUpLater = 'sign-up-later',
}

export type SignUpValidation = {
  type: 'invite' | 'referral'
  code: string
  name: string
  image: string
}

export type WalletAnalyzer = {
  isAnimating: boolean
  userType: WalletAnalyzerUserTypeEnum
  title: string
  comments: string[]
  bonusPoints: {
    points: number | undefined
    address: string
  }[]
  meta?: components['schemas']['WalletAnalyzerMeta']
}

export type SignUpStep = {
  text: string
  callToAction: string
  sub: string
  action: () => void
  isComplete: boolean
  isLater: boolean
}

export type SignUpStatus = {
  wallet?: 'success' | 'failure'
  twitter?: 'success' | 'failure'
  discord?: 'success' | 'failure'
  hasSkippedTwitter?: boolean
}

export type RewardsPageParams = {
  isLoading: boolean
  view?: RewardsPageViewEnum
  linkWallet: {
    isOpen: boolean
    onClose: () => void
    onSignUp: () => void
  }
  switchProfile: {
    onSwitchProfile: () => void
    isOpen: boolean
    onClose: () => void
  }
  connectedSocials: Record<Socials, boolean>
  // For handling step increments during sign up redirect
  signUpStatus: SignUpStatus
  account?: Address
  accountExistsInProfile: boolean
  isWalletConnected: boolean
  isLoggedIn: boolean
  hasProfile: boolean
  hasPreseasonPoints: boolean
  profile?: string
  profileName: string
  profileImageUrl?: string
  currentSeason?: components['schemas']['Season']
  fullProfile?: components['schemas']['ProfileData']
  publicProfile?: components['schemas']['ProfilePublicData']
  preseasonPoints?: components['schemas']['PreseasonPoints']
  numberOfWeeks: number
  walletNetPositions: WalletNetPosition[]
  validatedInviteCode?: string
  migrationPoints?: components['schemas']['WalletAnalyzer']
  signUpValidation?: SignUpValidation
  isSignInModalOpen: boolean
  isSignUpModalOpen: boolean
  isSwitchProfileViewOnly: boolean
  hasClickedSignUpLater: boolean
  walletAnalyzer: WalletAnalyzer
  completedRetweets: Partial<Record<RewardedTweet, boolean>>
  resetSignUpSteps?: {}
  onNavigateBackToInviteCode: () => void
  onNavigateToSignUp: () => void
  onNavigateToAuctions: () => void
  onNavigateToInvites: () => void
  onSignUpLater: () => void
  onSignInModalOpen: () => void
  onUpdateProfile?: (task: string, value: boolean) => Promise<void>
  onSignInModalClose: (token?: string) => void
  onSignUpModalOpen: () => void
  onSignUpModalClose: () => void
  onClaimSeasonRewards: () => void
  onDeleteProfile: () => void
  onRemoveTwitter: () => void
  onRemoveDiscord: () => void
  onRemoveWallet: (walletAddress: string) => void
  onSignMessage: (signer: Signer, nonce: string) => Promise<string>
  onConnect: () => void
  onAnalyzeWallet: () => void
  onCloseWalletAnalyzer: () => void
  onValidateInviteCode?: (code: string) => void
  onValidateReferralCode?: (code: string) => void
  onLogout?: () => void
  onLinkWalletNewUser?: (
    referralType?: 'invite' | 'referral',
    code?: string
  ) => Promise<void>
  onLinkWallet?: (isSkipWalletAnalyzerAnimation?: boolean) => Promise<void>
  onLinkExtraWallet: (isSkipWalletAnalyzerAnimation?: boolean) => Promise<void>
  onLinkMultiSigWallet?: (address: Address) => Promise<void>
  onSignInWithWallet?: () => Promise<void>
  // These require the original URL to be passed in as state so we can redir after the fact.
  onLinkTwitter?: (redirPath?: string) => void
  onLinkDiscord?: (redirPath?: string) => Promise<void>
  onUnlinkWallet?: (address: string) => void
  onUnlinkTwitter?: () => void
  onUnlinkDiscord?: () => void
  onRetweet: (tweet: EligibleRetweets) => void
}
