import { JsonRpcProvider, FallbackProvider } from '@ethersproject/providers'
import { Address } from '../model'
import { PageVaultQuery } from '../../gql/vaults/graphql'
import {
  useAragonGovernanceProposals,
  VaultProposal,
} from './use-aragon-governance-proposals'
import { useMemo } from 'react'
import TermVaultStrategyABI from '../../abi/vault/TermVaultStrategy.json'
import { TermVaultStrategy } from '../../abi-generated'
import { BigNumber, Contract, FixedNumber } from 'ethers'
import { useCurrentTime } from './helper-hooks'
import dayjs from 'dayjs'
import { MappedGovernanceProposal } from '../../models/vault'
import { useChainConfig } from '../../providers/config'
import { isAddress } from 'ethers/lib/utils'
import { TokenInfo, useCurrencies } from './use-currencies'
import { multiply } from '../../helpers/math'
import { bigToFixedNumber } from '../../helpers/conversions'
import { useDelayModifier } from './use-delay-modifier'

interface DecodedTxData {
  methodUsed: string
  value: string
  value2?: string
}

const proposedMethodToCurrentValueMap: {
  [method: string]: keyof NonNullable<PageVaultQuery['termVaultStrategy']>
} = {
  setTimeToMaturityThreshold: 'timeToMaturityThreshold',
  setRepoTokenConcentrationLimit: 'repoTokenConcentrationLimit',
  setRequiredReserveRatio: 'requiredReserveRatio',
  setCollateralTokenParams: 'minCollateralRatios',
  setDiscountRateAdapter: 'discountRateAdapter',
  setDiscountRateMarkup: 'discountRateMarkup',
  setRepoTokenBlacklist: 'hiddenAssets',
  setTermController: 'termController',

  // below needs to come from RPC calls
  // setPerformanceFee: '',
  // pauseStrategy: '',
  // unpauseStrategy: '',
  // pauseDeposit: '',
  // unpauseDeposit: '',
  // shutdownStrategy: '',
  // acceptManagement: '',
}

export function useGovernance(
  chainId: string,
  strategyAddress: Address,
  vaultSubgraphData: PageVaultQuery['termVaultStrategy'] | undefined,
  provider: JsonRpcProvider | FallbackProvider | undefined
): {
  aragonDaoUrl: string
  pendingGovernance: MappedGovernanceProposal[]
  pendingExecution: MappedGovernanceProposal[]
  hasExpiredTransactions: boolean
} {
  const chainConfig = useChainConfig(chainId)
  const currentTime = useCurrentTime()

  // strategy contract used to decode tx data
  const strategyContract = useMemo(() => {
    return new Contract(
      strategyAddress,
      TermVaultStrategyABI,
      provider
    ) as TermVaultStrategy
  }, [strategyAddress, provider])

  // fetch txNonce from delay modifier contract
  const delayModifierAddress = useMemo(
    () => vaultSubgraphData?.delay,
    [vaultSubgraphData]
  )
  const { txNonce, txCooldown, txExpiration } = useDelayModifier(
    chainId,
    delayModifierAddress,
    provider
  )

  // fetch data from aragon
  const { proposals: aragonProposals } = useAragonGovernanceProposals(
    chainId,
    strategyAddress
  )

  // generate dao url
  const aragonDaoUrl = useMemo(() => {
    if (!chainId || !vaultSubgraphData?.dao || !chainConfig?.chainName)
      return '#'
    return `https://app.aragon.org/#/daos/${chainConfig.chainName.toLowerCase()}/${vaultSubgraphData.dao}`
  }, [chainId, vaultSubgraphData?.dao, chainConfig?.chainName])

  // combine aragon proposals with subgraph data
  const mappedGovernanceProposals: MappedGovernanceProposal[] | undefined =
    useMemo(
      () =>
        mergeDelayQueueWithAragonProposals(
          strategyContract,
          aragonProposals,
          vaultSubgraphData,
          txCooldown,
          txExpiration
        ),
      [
        aragonProposals,
        strategyContract,
        vaultSubgraphData,
        txCooldown,
        txExpiration,
      ]
    )

  // get currency info for collateral ratio updates
  const currencyAddresses = useMemo(() => {
    const tokenInfo: { [chainId: string]: TokenInfo[] | undefined } = {}

    if (!mappedGovernanceProposals) {
      return undefined
    }

    mappedGovernanceProposals.forEach((proposal) => {
      if (
        proposal.isCollateralRatioUpdate &&
        isAddress(proposal.proposedValue)
      ) {
        const address = proposal.proposedValue.toLowerCase()
        if (!tokenInfo[chainId]) {
          tokenInfo[chainId] = []
        }
        tokenInfo[chainId]?.push({
          address,
          isRepoToken: false,
          version: 'x',
        })
      }
      if (
        proposal.methodProposed === 'setRepoTokenBlacklist' &&
        isAddress(proposal.proposedValue)
      ) {
        const address = proposal.proposedValue.toLowerCase()
        if (!tokenInfo[chainId]) {
          tokenInfo[chainId] = []
        }
        tokenInfo[chainId]?.push({
          address,
          isRepoToken: false, // note: setting false here as we just need symbol information, no need to check repo token config
          version: 'x',
        })
      }
    })
    return tokenInfo
  }, [chainId, mappedGovernanceProposals])

  const currencyInfo = useCurrencies(currencyAddresses, provider)

  // add currency symbol + value mapping to proposals
  const mappedProposalsWithCurrencies = useMemo(() => {
    if (!mappedGovernanceProposals) {
      return undefined
    }

    return mappedGovernanceProposals
      .map((proposal) => {
        // collateral ratio updates
        if (
          proposal.isCollateralRatioUpdate &&
          isAddress(proposal.proposedValue) &&
          proposal.proposedValue2
        ) {
          const currency = currencyInfo?.[chainId]?.find(
            (c) =>
              c.address.toLowerCase() === proposal.proposedValue.toLowerCase()
          )
          const mappedValues = mapGovernanceValues(
            proposal.methodProposed,
            proposal.currentValue,
            proposal.proposedValue2
          )
          if (currency) {
            return {
              ...proposal,
              methodProposed: mappedValues[0],
              currentValue: mappedValues[1],
              proposedValue2: mappedValues[2],
              proposedValue: currency?.symbol,
            }
          }
        }
        // repo token blacklist
        if (
          proposal.methodProposed === 'setRepoTokenBlacklist' &&
          isAddress(proposal.proposedValue)
        ) {
          const proposedValueCurrency = currencyInfo?.[chainId]?.find(
            (c) =>
              c.address.toLowerCase() === proposal.proposedValue.toLowerCase()
          )
          const currentValueCurrencies = currencyInfo?.[chainId]?.filter((c) =>
            proposal.currentValue?.split(',').includes(c.address.toLowerCase())
          )
          if (proposedValueCurrency) {
            return {
              ...proposal,
              methodProposed: mapGovernanceValues(
                proposal.methodProposed,
                proposal.currentValue,
                proposal.proposedValue
              )[0],
              currentValue:
                currentValueCurrencies?.map((c) => c.symbol).join(', ') ?? '',
              proposedValue: proposedValueCurrency?.symbol ?? '',
            }
          }
        }
        // all other proposals
        else {
          const mappedValues = mapGovernanceValues(
            proposal.methodProposed,
            proposal.currentValue,
            proposal.proposedValue
          )
          return {
            ...proposal,
            methodProposed: mappedValues[0],
            currentValue: mappedValues[1],
            proposedValue: mappedValues[2],
          }
        }
        return proposal
      })
      .sort((a, b) => a.delay.queueNonce - b.delay.queueNonce)
  }, [chainId, currencyInfo, mappedGovernanceProposals])

  // Group proposals into pending governance or pending execution, filter out proposals that are not relevant
  const { pendingGovernance, pendingExecution, hasExpiredTransactions } =
    useMemo(() => {
      if (!mappedProposalsWithCurrencies || txNonce === undefined) {
        return {
          pendingGovernance: [],
          pendingExecution: [],
        }
      }

      const pendingGovernance = mappedProposalsWithCurrencies.filter(
        (proposal) => {
          const endTimestamp = dayjs.unix(proposal.endTimestamp)
          const queueNonce = proposal.delay.queueNonce
          return currentTime.isBefore(endTimestamp) && queueNonce >= txNonce
        }
      )

      const pendingExecution = mappedProposalsWithCurrencies.filter(
        (proposal) => {
          const endTimestamp = dayjs.unix(proposal.endTimestamp)
          const expiredTimestamp = dayjs.unix(proposal.expiryTimestamp)
          const queueNonce = proposal.delay.queueNonce
          return (
            currentTime.isAfter(endTimestamp) &&
            currentTime.isBefore(expiredTimestamp) &&
            queueNonce >= txNonce
          )
        }
      )

      const hasExpiredTransactions =
        mappedProposalsWithCurrencies.filter((proposal) => {
          const expiredTimestamp = dayjs.unix(proposal.expiryTimestamp)
          const queueNonce = proposal.delay.queueNonce
          return currentTime.isAfter(expiredTimestamp) && queueNonce >= txNonce
        }).length > 0

      return {
        pendingGovernance,
        pendingExecution,
        hasExpiredTransactions,
      }
    }, [currentTime, mappedProposalsWithCurrencies, txNonce])

  // possible to set the wal cap, req reserve and concentration cap + add collateral tokens
  return {
    aragonDaoUrl,
    pendingGovernance,
    pendingExecution,
    hasExpiredTransactions: !!hasExpiredTransactions,
  }
}

const mergeDelayQueueWithAragonProposals = (
  strategyContract: TermVaultStrategy,
  aragonProposals?: VaultProposal[],
  vaultSubgraphData?: PageVaultQuery['termVaultStrategy'],
  txCooldown?: number,
  txExpiration?: number
): MappedGovernanceProposal[] => {
  if (!vaultSubgraphData) {
    return []
  }

  const mapped = vaultSubgraphData.delayQueue.map((dq) => {
    // try to find a matching Aragon proposal by comparing delay queue tx hashes.
    const matchingProposal = aragonProposals?.find((proposal) => {
      return (
        proposal.delayQueueTxHash &&
        dq.delayQueueTxHash &&
        proposal.delayQueueTxHash.toLowerCase() ===
          dq.delayQueueTxHash.toLowerCase()
      )
    })

    // decode the calldata from the delay queue entry.
    const decodedCalldata = decodeTxData(dq.calldata, strategyContract)
    if (!decodedCalldata) {
      return undefined
    }

    const methodProposed = decodedCalldata?.methodUsed || ''
    const isCollateralRatioUpdate =
      methodProposed === 'setCollateralTokenParams'

    // find the subgraph field based on the method proposed.
    const subgraphField = proposedMethodToCurrentValueMap?.[methodProposed]

    let currentValue
    if (isCollateralRatioUpdate && decodedCalldata?.value) {
      // for collateral ratio updates, match the token from decoded calldata with the minCollateralRatios list.
      currentValue = vaultSubgraphData?.minCollateralRatios?.find(
        (ct) =>
          ct.collateralToken.toLowerCase() ===
          decodedCalldata.value.toLowerCase()
      )?.ratio
    } else if (methodProposed === 'setRepoTokenBlacklist') {
      // for repo token blacklist updates, collapse the hidden assets array into a string.
      currentValue = vaultSubgraphData?.hiddenAssets?.toString()
    } else if (subgraphField) {
      currentValue = vaultSubgraphData[subgraphField]
    }

    // build merged governance proposal object.
    // if there's no matching Aragon proposal, use empty/default values.
    const mgp: MappedGovernanceProposal = {
      isCollateralRatioUpdate,
      methodProposed: decodedCalldata?.methodUsed || '',
      proposedValue: decodedCalldata?.value,
      proposedValue2: decodedCalldata?.value2,
      currentValue: currentValue,
      proposalTxHash: matchingProposal?.txHash || '',
      executedTimestamp: Number(dq.blockTimestamp) || 0,
      endTimestamp: (Number(dq.blockTimestamp) ?? 0) + (txCooldown ?? 0),
      expiryTimestamp:
        (Number(dq.blockTimestamp) ?? 0) +
        (txCooldown ?? 0) +
        (txExpiration ?? 0),
      proposalUrl: matchingProposal?.proposalUrl || '',
      delay: {
        txHash: dq.delayQueueTxHash,
        queueNonce: Number(dq.delayQueueNonce),
        blockTimestamp: Number(dq.blockTimestamp),
        blockNumber: Number(dq.blockNumber),
        calldata: dq.calldata,
        decodedCalldata: decodedCalldata,
      },
    }

    return mgp
  })

  return mapped.filter(Boolean) as MappedGovernanceProposal[]
}

const decodeTxData = (
  calldata: string,
  strategyContract: TermVaultStrategy
): DecodedTxData | undefined => {
  try {
    const txDescription = strategyContract.interface.parseTransaction({
      data: calldata,
    })

    const methodUsed = txDescription.name
    const [arg0, arg1] = txDescription.args

    let value = arg0?.toString() || ''
    let value2 = undefined

    // this method uses multiple args
    if (methodUsed === 'setCollateralTokenParams') {
      value2 = arg1?.toString() || ''
    }

    // Return a clean object with decoded fields
    return {
      methodUsed,
      value,
      value2,
    }
  } catch (error) {
    console.log('Failed to decode calldata:', error)
    return undefined
  }
}

const mapGovernanceValues = (
  proposedMethod: string,
  currentValue: string,
  proposedValue: string
) => {
  switch (proposedMethod) {
    case 'setTimeToMaturityThreshold':
      return [
        'WAL Cap',
        `${Number(currentValue) / 86400} days`,
        `${Number(proposedValue) / 86400} days`,
      ]
    case 'setRepoTokenConcentrationLimit':
      return [
        'Concentration Cap',
        formatPercentage(currentValue),
        formatPercentage(proposedValue),
      ]
    case 'setRequiredReserveRatio':
      return [
        'Required Reserve',
        formatPercentage(currentValue),
        formatPercentage(proposedValue),
      ]
    case 'setDiscountRateMarkup':
      return [
        'Discount Rate Markup',
        formatPercentage(currentValue),
        formatPercentage(proposedValue),
      ]
    case 'setCollateralTokenParams':
      return [
        'Add Collateral',
        currentValue ? formatPercentage(currentValue) : '-',
        formatPercentage(proposedValue),
      ]
    case 'setDiscountRateAdapter':
      return ['Discount Rate Adapter', currentValue, proposedValue]
    case 'setRepoTokenBlacklist':
      return ['Repo Token Blacklist', currentValue, proposedValue]
    case 'setTermController':
      return ['Term Controller', currentValue, proposedValue]
    default:
      return [proposedMethod, currentValue, proposedValue]
  }
}

const formatPercentage = (value: string): string => {
  try {
    const asFixed = bigToFixedNumber(BigNumber.from(value), 18)
    const multiplied = multiply(asFixed, FixedNumber.fromString('100'))
    return `${multiplied.toString()}%`
  } catch {
    return value
  }
}
