import { useMemo } from 'react'
import { useJsonRestCalls } from '../../hooks/helpers/rest'
import { Address } from '../model'

const URL =
  'https://term-finance-vault-proposals.s3.us-west-1.amazonaws.com/vaultProposals.json'

export type VaultProposal = {
  blockNumber: number
  methodProposed: string
  proposedValue: string
  proposedValue2: string
  executedTimestamp: number
  endTimestamp: number
  txHash: string
  delayQueueTxHash: string
  proposalUrl?: string
}

const METHODS_WATCHED = [
  'setTimeToMaturityThreshold',
  'setRequiredReserveRatio',
  'setRepoTokenConcentrationLimit',
  'setCollateralTokenParams',
  'setDiscountRateAdapter',
  'setDiscountRateMarkup',
  'setRepoTokenBlacklist',
  'setTermController',
  // 'setPerformanceFee',
  // 'pauseStrategy',
  // 'unpauseStrategy',
  // 'pauseDeposit',
  // 'unpauseDeposit',
  // 'shutdownStrategy',
  // 'acceptManagement',
]

export function useAragonGovernanceProposals(
  chainId: string,
  strategyAddress: Address
): {
  proposals: VaultProposal[] | undefined
} {
  const { data: governanceProposals } = useJsonRestCalls<
    { '#call': RequestInfo },
    { [chainId: string]: { [strategyAddress: string]: VaultProposal[] } }
  >({
    '#call': {
      url: URL,
      method: 'GET',
    } as RequestInfo,
  })

  const proposals = useMemo(() => {
    const strategyProposals =
      governanceProposals?.[chainId]?.[strategyAddress] || undefined

    if (!strategyProposals) {
      return []
    }

    return strategyProposals.filter((proposal) =>
      METHODS_WATCHED.includes(proposal.methodProposed)
    )
  }, [governanceProposals, chainId, strategyAddress])

  return {
    proposals,
  }
}
