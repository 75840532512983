import { JsonRpcProvider, FallbackProvider } from '@ethersproject/providers'
import { Address } from '../model'
import TermDelayModifierABI from '../../abi/vault/TermDelayModifier.json'
import { TermDelayModifier } from '../../abi-generated'
import { useEffect, useMemo } from 'react'
import { Contract } from 'ethers'
import { MultichainCalls, useMultichainCalls } from './helper-hooks'
import { CallResult } from '@usedapp/core'
import { captureException } from '@sentry/react'

// wired up for single chain support
export function useDelayModifier(
  chainId: string,
  delayModfier: Address,
  provider: JsonRpcProvider | FallbackProvider | undefined
): {
  txNonce?: number
  txCooldown?: number
  txExpiration?: number
} {
  const delayModifierContract = useMemo(() => {
    if (delayModfier) {
      return new Contract(
        delayModfier,
        TermDelayModifierABI,
        provider
      ) as TermDelayModifier
    }
  }, [provider, delayModfier])

  const contractCalls = useMemo(() => {
    const calls: MultichainCalls = {}

    if (chainId && delayModifierContract) {
      calls[chainId] = [
        {
          contract: delayModifierContract,
          method: 'txNonce',
          args: [],
        },
        {
          contract: delayModifierContract,
          method: 'txExpiration',
          args: [],
        },
        {
          contract: delayModifierContract,
          method: 'txCooldown',
          args: [],
        },
      ]
    }
    return calls
  }, [chainId, delayModifierContract])

  const { txNonce, txCooldown, txExpiration } =
    useBlockchainCalls(contractCalls) || {}

  return { txNonce, txCooldown, txExpiration }
}

const useBlockchainCalls = (
  calls: MultichainCalls
):
  | { txNonce?: number; txCooldown?: number; txExpiration?: number }
  | undefined => {
  const blockchainResults = useMultichainCalls(calls)

  // Process any errors from the call results.
  const blockchainErrors = useMemo(() => {
    const errorsByChain: Record<string, any> = {}
    if (blockchainResults) {
      Object.entries(blockchainResults).forEach(([chainId, chainResults]) => {
        const chainErrors = chainResults
          .filter((result) => result && result.error !== undefined)
          .map((result) => result?.error)
        if (chainErrors.length > 0) {
          errorsByChain[chainId] = chainErrors
        }
      })
    }
    return Object.keys(errorsByChain).length > 0 ? errorsByChain : undefined
  }, [blockchainResults])

  useEffect(() => {
    if (blockchainErrors) {
      Object.entries(blockchainErrors).forEach(([chainId, errors]) => {
        console.error(`Error on ${chainId}:`, errors)
        captureException(errors)
      })
    }
  }, [blockchainErrors])

  const aggregatedResult = useMemo(() => {
    if (!blockchainResults) return undefined
    const chainIds = Object.keys(blockchainResults)
    if (chainIds.length === 0) return undefined

    const firstChainId = chainIds[0]
    const chainResults = blockchainResults[firstChainId]

    // extract a number value from a call result
    const extractValue = (
      result?: CallResult<Contract, string>
    ): number | undefined => {
      if (result && result.error === undefined && result.value !== undefined) {
        // convert the first element of the result (an array) to a number.
        return Number(result.value[0]?.toString())
      }
      return undefined
    }

    const txNonce = extractValue(chainResults[0])
    const txExpiration = extractValue(chainResults[1])
    const txCooldown = extractValue(chainResults[2])

    return { txNonce, txCooldown, txExpiration }
  }, [blockchainResults])

  return aggregatedResult
}
