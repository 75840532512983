import { Divider, Flex, Grid, GridProps, Link, Text } from '@chakra-ui/react'
import { InfoOutlineIcon } from '@chakra-ui/icons'
import { HStack, VStack } from '../../../../components/elements/Stack'
import Tooltip from '../../../../components/elements/Tooltip'
import { ArrowUpRight } from '../../../../components/elements/Icons'
import { MappedGovernanceProposal } from '../../../../models/vault'
import { useEffect, useRef, useState } from 'react'
import TokenIconLabel from '../../../../components/elements/TokenLabelIcon'

function StatLabel({ label }: { label: React.ReactNode }) {
  return (
    <VStack spacing="9px" align="start" w="full">
      <Text color="gray.6" variant="body-md/normal" whiteSpace="nowrap">
        {label}
      </Text>
    </VStack>
  )
}

function StatValue({ value }: { value?: string }) {
  const textRef = useRef<HTMLDivElement>(null)
  const [isValueTruncated, setIsValueTruncated] = useState(false)

  useEffect(() => {
    if (textRef.current) {
      const { scrollWidth, offsetWidth } = textRef.current
      setIsValueTruncated(scrollWidth > offsetWidth)
    }
  }, [value])

  return (
    <VStack spacing="9px" align="start" w="full">
      <Tooltip noDelay label={isValueTruncated ? value : undefined}>
        <Text
          ref={textRef}
          color="blue.5"
          variant="body-md/semibold"
          maxW="80px"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          overflow="hidden"
          lineHeight="normal"
        >
          {value ?? (
            <Text as="span" color="gray.6">
              &mdash;
            </Text>
          )}
        </Text>
      </Tooltip>
    </VStack>
  )
}

type PendingChangeType = 'governance' | 'execution'

const gridStyles: GridProps = {
  gridTemplateColumns: { base: '190px 80px 80px' },
  gap: { base: 16 },
  rowGap: { base: 6 },
}

export default function PendingChanges({
  type,
  changes,
}: {
  type: PendingChangeType
  changes: MappedGovernanceProposal[]
}) {
  return (
    <Flex
      flexDir="column"
      w="100%"
      maxW="535px"
      overflowX="auto"
      overflowY="hidden"
    >
      {/* Header */}
      <Grid {...gridStyles}>
        <StatLabel
          label={
            <HStack spacing="4px">
              {type === 'governance' && (
                <Text as="span">Pending Governance</Text>
              )}
              {type === 'execution' && <Text as="span">Pending Execution</Text>}
              <Tooltip
                label={
                  type === 'governance'
                    ? 'Proposals listed here can be veteod in Aragon.'
                    : 'Proposals listed here have passed governance and are pending execution.'
                }
                placement="top-start"
                w="304px"
                offset={[-5, 6]}
              >
                <InfoOutlineIcon color="gray.4" />
              </Tooltip>
            </HStack>
          }
        />
        <StatLabel label="Current" />
        <StatLabel label="Proposed" />
      </Grid>

      <Divider color="blue.9" mb={4} />

      {/* Changes */}
      {changes.map((item) => (
        <Grid key={item.delay.txHash} {...gridStyles} alignItems="center">
          {/* Column 1: Method Name or Collateral Token Icon */}
          <HStack spacing={1}>
            {type === 'governance' && (
              <Link href={item.proposalUrl} isExternal>
                <ArrowUpRight h="24px" w="24px" color="gray.5" />
              </Link>
            )}
            {item.isCollateralRatioUpdate ? (
              <Flex gap={1}>
                <TokenIconLabel token={item.proposedValue} isSemiBold />
              </Flex>
            ) : (
              <Text as="span" color="blue.9" variant="body-md/semibold">
                {item.methodProposed}
              </Text>
            )}
          </HStack>

          {/* Column 2: Current Value */}
          <StatValue
            value={
              item.currentValue && item.currentValue !== ''
                ? item.currentValue
                : undefined
            }
          />

          {/* Column 3: Proposed Value */}
          <StatValue
            value={
              item.isCollateralRatioUpdate
                ? item.proposedValue2
                : item.proposedValue
            }
          />
        </Grid>
      ))}
    </Flex>
  )
}
