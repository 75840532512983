import { JsonRpcProvider, FallbackProvider } from '@ethersproject/providers'
import { Address } from '../model'
import { BigNumber, Contract, ethers, FixedNumber, Signer } from 'ethers'
import { useCallback, useEffect, useMemo } from 'react'
import { useChainConfig, useConfig } from '../../providers/config'
import { CallObject, MultichainCalls, useMultichainCalls } from './helper-hooks'
import TermVaultStrategyABI from '../../abi/vault/TermVaultStrategy.json'
import { TermVaultStrategy } from '../../abi-generated'
import GnosisSafeABI from '../../abi-external/GnosisSafe.json'
import { GnosisSafe } from '../../abi-generated'
import TermDelayModifierABI from '../../abi/vault/TermDelayModifier.json'
import { TermDelayModifier } from '../../abi-generated'
import { captureException } from '@sentry/react'
import { TransactionStatus, useSendTransaction } from '@usedapp/core'
import { fixedToBigNumber } from '../../helpers/conversions'
import { waitForStatus } from '../../helpers/wait'
import SafeApiKit from '@safe-global/api-kit'
import Safe from '@safe-global/protocol-kit'
import { MetaTransactionData, OperationType } from '@safe-global/types-kit'
import { useAnalytics } from '../analytics/use-analytics'

export function useVaultMutations(
  account: Address | undefined,
  strategyAddress: Address,
  chainId: string,
  proposerAddress: Address | undefined,
  delayModifierAddress: Address,
  signer: Signer | undefined,
  provider: JsonRpcProvider | FallbackProvider | undefined,
  readFromSubgraph: () => void
): {
  // deposit into vault
  deposit: (chainId: string, amount: FixedNumber) => Promise<void>
  // withdraw from vault
  withdraw: (
    chainId: string,
    amount: FixedNumber,
    maxLoss?: FixedNumber
  ) => Promise<void>
  // set time to maturity threshold
  setTimeToMaturityThreshold: (
    chainId: string,
    timeToMaturityThreshold: number
  ) => Promise<void>
  // set required reserve ratio
  setRequiredReserveRatio: (
    chainId: string,
    requiredReserveRatio: FixedNumber
  ) => Promise<void>
  // set repo token concentration limit
  setRepoTokenConcentrationLimit: (
    chainId: string,
    repoTokenConcentrationLimit: FixedNumber
  ) => Promise<void>
  // set collateral token parameters
  setCollateralTokenParams: (
    chainId: string,
    collateralToken: Address,
    minCollateralRatio: FixedNumber
  ) => Promise<void>
  // set discount rate adapter
  setDiscountRateAdapter: (
    chainId: string,
    discountRateAdapter: Address
  ) => Promise<void>
  // set discount rate markup
  setDiscountRateMarkup: (
    chainId: string,
    discountRateMarkup: FixedNumber
  ) => Promise<void>
  // set repo token blacklist
  setRepoTokenBlacklist: (
    chainId: string,
    repoTokenAddress: Address,
    isBlacklisted: boolean
  ) => Promise<void>
  // set term controller
  setTermController: (chainId: string, termController: Address) => Promise<void>
  // set performance fee
  setPerformanceFee: (
    chainId: string,
    performanceFee: FixedNumber
  ) => Promise<void>
  // pause strategy
  pauseStrategy: (chainId: string) => Promise<void>
  // unpause strategy
  unpauseStrategy: (chainId: string) => Promise<void>
  // pause deposit
  pauseDeposit: (chainId: string) => Promise<void>
  // unpause deposit
  unpauseDeposit: (chainId: string) => Promise<void>
  // shutdown withdraw
  shutdownStrategy: (chainId: string) => Promise<void>
  // accept vault management
  acceptManagement: (chainId: string) => Promise<void>
  // execute vault parameter update
  executeVaultParameterUpdate: (
    chainId: string,
    queueNonce: number,
    calldata: string
  ) => Promise<void>
  // skip expired transactions in delay modifier
  skipExpiredTransactions: (chainId: string) => Promise<void>
} {
  const config = useConfig()
  const { trackEvent } = useAnalytics()

  const validateActiveNetwork = useCallback(
    async (chainId: number) => {
      const activeNetwork = await signer?.provider?.getNetwork()
      return (
        activeNetwork?.chainId &&
        activeNetwork.chainId.toString() === chainId.toString()
      )
    },
    [signer]
  )

  const strategyContract = useMemo(() => {
    return new Contract(
      strategyAddress,
      TermVaultStrategyABI,
      provider
    ) as TermVaultStrategy
  }, [strategyAddress, provider])

  const proposerContract = useMemo(() => {
    return proposerAddress
      ? (new Contract(proposerAddress, GnosisSafeABI, provider) as GnosisSafe)
      : undefined
  }, [proposerAddress, provider])

  const delayModifierContract = useMemo(() => {
    return delayModifierAddress
      ? (new Contract(
          delayModifierAddress,
          TermDelayModifierABI,
          provider
        ) as TermDelayModifier)
      : undefined
  }, [delayModifierAddress, provider])

  const chainConfig = useChainConfig(chainId)
  const apiKit = useMemo(
    () => new SafeApiKit({ chainId: BigInt(chainId) }),
    [chainId]
  )
  const rpcUrl = chainConfig?.rpcUrl
  const protocolKit = useMemo(
    () =>
      rpcUrl && account && proposerAddress
        ? Safe.init({
            provider: (window as any).ethereum,
            signer: ethers.utils.getAddress(account),
            safeAddress: proposerAddress,
          })
        : undefined,
    [rpcUrl, account, proposerAddress]
  )

  // Safe proposer owner calls

  // #region
  const proposerContractCalls: MultichainCalls = useMemo(() => {
    let calls = [] as CallObject[]
    if (account && proposerContract) {
      calls = [
        {
          contract: proposerContract,
          method: 'isOwner',
          args: [account],
        },
      ]
    }
    return {
      [chainId]: calls,
    }
  }, [account, chainId, proposerContract])
  const isProposerResponse = useMultichainCalls(proposerContractCalls)

  const isProposerResults = useMemo(() => {
    const result: { [chainId: string]: any[] } = {}
    Object.entries(isProposerResponse).forEach(
      ([chainId, resultsAndErrors]) => {
        result[chainId] = resultsAndErrors
          .filter((resultOrError) => !resultOrError?.error)
          .map((resultOrError) => resultOrError?.value)
      }
    )
    return result
  }, [isProposerResponse])

  const isProposerErrors = useMemo(() => {
    const errors: { [chainId: string]: any[] } = {}

    if (isProposerResponse) {
      Object.entries(isProposerResponse).forEach(
        ([chainId, resultsAndErrors]) => {
          const chainErrors = resultsAndErrors
            .filter((resultOrError) => !!resultOrError?.error)
            .map((resultOrError) => resultOrError?.error)

          if (chainErrors.length > 0) {
            errors[chainId] = chainErrors
          }
        }
      )
    }

    return errors
  }, [isProposerResponse])

  useEffect(() => {
    if (isProposerErrors) {
      Object.entries(isProposerErrors).forEach(([chainId, chainError]) => {
        console.error(`Error on ${chainId}: ${chainError}`)
        captureException(chainError)
      })
    }
  }, [isProposerErrors])

  const isProposer = useMemo(() => {
    if (!isProposerResults) return null

    let value: boolean | undefined

    Object.entries(isProposerResults).forEach(([_, values]) => {
      const isProposerValue = values.slice(0, 1)?.[0]

      if (strategyAddress && isProposerValue) {
        value = isProposerValue?.[0]
      }
    })
    return value
  }, [isProposerResults, strategyAddress])

  // #endregion

  // Safe contract interaction

  // #region

  // if connected wallet is proposer signer, send transaction to proposer with instructions
  // to call executeTransactionFromModule on delay modifier
  const sendTransactionToProposerSafe = useCallback(
    async (targetCallData: string) => {
      console.log(
        'connected wallet is proposer, using gnosis safe to execute transaction'
      )

      if (
        account &&
        protocolKit &&
        proposerContract &&
        delayModifierContract &&
        isProposer
      ) {
        console.log(
          'strategy address: %o, proposer address: %o, delay modifier: %o',
          strategyAddress,
          proposerContract.address,
          delayModifierContract.address
        )

        const moduleCallData =
          delayModifierContract.interface.encodeFunctionData(
            'execTransactionFromModule',
            [ethers.utils.getAddress(strategyAddress), 0, targetCallData, 0]
          )

        console.info('generating safe transaction data...')

        const safeTransactionData: MetaTransactionData = {
          to: ethers.utils.getAddress(delayModifierContract.address),
          value: '0',
          data: moduleCallData,
          operation: OperationType.Call,
        }

        console.info('fetching latest nonce...')
        const nonce = await (
          await apiKit
        ).getNextNonce(ethers.utils.getAddress(proposerContract.address))

        const safeTransaction = await (
          await protocolKit
        ).createTransaction({
          transactions: [safeTransactionData],
          options: {
            safeTxGas: '0',
            gasPrice: '0',
            nonce,
          },
        })

        console.info('creating safe transaction hash')

        const safeTxHash = await (
          await protocolKit
        ).getTransactionHash(safeTransaction)

        const signature = await (
          await protocolKit
        ).signTypedData(safeTransaction)

        // Propose transaction to the service
        await apiKit.proposeTransaction({
          safeAddress: ethers.utils.getAddress(proposerContract.address),
          safeTransactionData: safeTransaction.data,
          safeTxHash: safeTxHash,
          senderAddress: ethers.utils.getAddress(account),
          senderSignature: signature.data,
        })

        console.info('transaction sent to safe')

        // Create a delay modifier transaction to execute the transaction

        console.info('creating execute transaction for delay mod...')

        const execNextCallData =
          delayModifierContract.interface.encodeFunctionData('executeNextTx', [
            ethers.utils.getAddress(strategyAddress),
            0,
            targetCallData,
            0,
          ])

        const safeExecNextTransactionData: MetaTransactionData = {
          to: ethers.utils.getAddress(delayModifierContract.address),
          value: '0',
          data: execNextCallData,
          operation: OperationType.Call,
        }

        console.info('fetching latest nonce...')
        const execNonce = await (
          await apiKit
        ).getNextNonce(ethers.utils.getAddress(proposerContract.address))

        const safeExecNextTransaction = await (
          await protocolKit
        ).createTransaction({
          transactions: [safeExecNextTransactionData],
          options: {
            safeTxGas: '0',
            gasPrice: '0',
            nonce: execNonce,
          },
        })

        console.info('creating safe transaction hash')

        const safeExecNextTxHash = await (
          await protocolKit
        ).getTransactionHash(safeExecNextTransaction)

        const execNextSignature = await (
          await protocolKit
        ).signTypedData(safeExecNextTransaction)

        // Propose transaction to the service
        await apiKit.proposeTransaction({
          safeAddress: ethers.utils.getAddress(proposerContract.address),
          safeTransactionData: safeExecNextTransaction.data,
          safeTxHash: safeExecNextTxHash,
          senderAddress: ethers.utils.getAddress(account),
          senderSignature: execNextSignature.data,
        })

        console.info('execNextTx sent to safe')
      }
    },
    [
      proposerContract,
      delayModifierContract,
      protocolKit,
      strategyAddress,
      isProposer,
      apiKit,
      account,
    ]
  )
  // #endregion
  // Set up transactions

  // #region

  const transactionStates = useMemo(
    () =>
      ({}) as {
        deposit: TransactionStatus | undefined
        withdraw: TransactionStatus | undefined
        setTimeToMaturityThreshold: TransactionStatus | undefined
        setRequiredReserveRatio: TransactionStatus | undefined
        setRepoTokenConcentrationLimit: TransactionStatus | undefined
        setCollateralTokenParams: TransactionStatus | undefined
        setDiscountRateAdapter: TransactionStatus | undefined
        setDiscountRateMarkup: TransactionStatus | undefined
        setRepoTokenBlacklist: TransactionStatus | undefined
        setTermController: TransactionStatus | undefined
        setPerformanceFee: TransactionStatus | undefined
        pauseStrategy: TransactionStatus | undefined
        unpauseStrategy: TransactionStatus | undefined
        pauseDeposit: TransactionStatus | undefined
        unpauseDeposit: TransactionStatus | undefined
        shutdownStrategy: TransactionStatus | undefined
        acceptManagement: TransactionStatus | undefined
        executeVaultParameterUpdate: TransactionStatus | undefined
        skipExpiredDelayModifier: TransactionStatus | undefined
      },
    []
  )

  const {
    sendTransaction: depositTransaction,
    state: depositState,
    resetState: resetDepositState,
  } = useSendTransaction()

  const {
    sendTransaction: withdrawTransaction,
    state: withdrawState,
    resetState: resetWithdrawState,
  } = useSendTransaction()

  const {
    sendTransaction: setTimeToMaturityThresholdTransaction,
    state: setTimeToMaturityThresholdState,
    resetState: resetSetTimeToMaturityThresholdState,
  } = useSendTransaction()

  const {
    sendTransaction: setRequiredReserveRatioTransaction,
    state: setRequiredReserveRatioState,
    resetState: resetSetRequiredReserveRatioState,
  } = useSendTransaction()

  const {
    sendTransaction: setRepoTokenConcentrationLimitTransaction,
    state: setRepoTokenConcentrationLimitState,
    resetState: resetSetRepoTokenConcentrationLimitState,
  } = useSendTransaction()

  const {
    sendTransaction: setCollateralTokenParamsTransaction,
    state: setCollateralTokenParamsState,
    resetState: resetSetCollateralTokenParamsState,
  } = useSendTransaction()

  const {
    sendTransaction: setDiscountRateAdapterTransaction,
    state: setDiscountRateAdapterState,
    resetState: resetSetDiscountRateAdapterState,
  } = useSendTransaction()

  const {
    sendTransaction: setDiscountRateMarkupTransaction,
    state: setDiscountRateMarkupState,
    resetState: resetSetDiscountRateMarkupState,
  } = useSendTransaction()

  const {
    sendTransaction: setRepoTokenBlacklistTransaction,
    state: setRepoTokenBlacklistState,
    resetState: resetSetRepoTokenBlacklistState,
  } = useSendTransaction()

  const {
    sendTransaction: setTermControllerTransaction,
    state: setTermControllerState,
    resetState: resetSetTermControllerState,
  } = useSendTransaction()

  const {
    sendTransaction: setPerformanceFeeTransaction,
    state: setPerformanceFeeState,
    resetState: resetSetPerformanceFeeState,
  } = useSendTransaction()

  const {
    sendTransaction: pauseStrategyTransaction,
    state: pauseStrategyState,
    resetState: resetPauseStrategyState,
  } = useSendTransaction()

  const {
    sendTransaction: unpauseStrategyTransaction,
    state: unpauseStrategyState,
    resetState: resetUnpauseStrategyState,
  } = useSendTransaction()

  const {
    sendTransaction: pauseDepositTransaction,
    state: pauseDepositState,
    resetState: resetPauseDepositState,
  } = useSendTransaction()

  const {
    sendTransaction: unpauseDepositTransaction,
    state: unpauseDepositState,
    resetState: resetUnpauseDepositState,
  } = useSendTransaction()

  const {
    sendTransaction: shutdownStrategyTransaction,
    state: shutdownStrategyState,
    resetState: resetShutdownStrategyState,
  } = useSendTransaction()

  const {
    sendTransaction: acceptManagementTransaction,
    state: acceptManagementState,
    resetState: resetAcceptManagementState,
  } = useSendTransaction()

  const {
    sendTransaction: executeVaultParameterUpdateTransaction,
    state: executeVaultParameterUpdateState,
    resetState: resetExecuteVaultParameterUpdateState,
  } = useSendTransaction()

  const {
    sendTransaction: skipExpiredDelayModifierTransaction,
    state: skipExpiredDelayModifierState,
    resetState: resetSkipExpiredDelayModifierState,
  } = useSendTransaction()

  useEffect(() => {
    transactionStates.deposit = depositState
  }, [depositState, transactionStates])
  useEffect(() => {
    transactionStates.withdraw = withdrawState
  }, [withdrawState, transactionStates])
  useEffect(() => {
    transactionStates.setTimeToMaturityThreshold =
      setTimeToMaturityThresholdState
  }, [setTimeToMaturityThresholdState, transactionStates])
  useEffect(() => {
    transactionStates.setRequiredReserveRatio = setRequiredReserveRatioState
  }, [setRequiredReserveRatioState, transactionStates])
  useEffect(() => {
    transactionStates.setRepoTokenConcentrationLimit =
      setRepoTokenConcentrationLimitState
  }, [setRepoTokenConcentrationLimitState, transactionStates])
  useEffect(() => {
    transactionStates.setCollateralTokenParams = setCollateralTokenParamsState
  }, [setCollateralTokenParamsState, transactionStates])
  useEffect(() => {
    transactionStates.setDiscountRateAdapter = setDiscountRateAdapterState
  }, [setDiscountRateAdapterState, transactionStates])
  useEffect(() => {
    transactionStates.setDiscountRateMarkup = setDiscountRateMarkupState
  }, [setDiscountRateMarkupState, transactionStates])
  useEffect(() => {
    transactionStates.setRepoTokenBlacklist = setRepoTokenBlacklistState
  }, [setRepoTokenBlacklistState, transactionStates])
  useEffect(() => {
    transactionStates.setTermController = setTermControllerState
  }, [setTermControllerState, transactionStates])
  useEffect(() => {
    transactionStates.setPerformanceFee = setPerformanceFeeState
  }, [setPerformanceFeeState, transactionStates])
  useEffect(() => {
    transactionStates.pauseStrategy = pauseStrategyState
  }, [pauseStrategyState, transactionStates])
  useEffect(() => {
    transactionStates.unpauseStrategy = unpauseStrategyState
  }, [unpauseStrategyState, transactionStates])
  useEffect(() => {
    transactionStates.pauseDeposit = pauseDepositState
  }, [pauseDepositState, transactionStates])
  useEffect(() => {
    transactionStates.unpauseDeposit = unpauseDepositState
  }, [unpauseDepositState, transactionStates])
  useEffect(() => {
    transactionStates.shutdownStrategy = shutdownStrategyState
  }, [shutdownStrategyState, transactionStates])
  useEffect(() => {
    transactionStates.acceptManagement = acceptManagementState
  }, [acceptManagementState, transactionStates])
  useEffect(() => {
    transactionStates.executeVaultParameterUpdate =
      executeVaultParameterUpdateState
  }, [executeVaultParameterUpdateState, transactionStates])
  useEffect(() => {
    transactionStates.skipExpiredDelayModifier = skipExpiredDelayModifierState
  }, [skipExpiredDelayModifierState, transactionStates])

  // #endregion

  // User methods

  // #region

  const depositIntoVault = useCallback(
    async (chainId: string, amount: FixedNumber) => {
      if (!account || !signer || !provider) {
        return
      }

      if (!signer) {
        return
      }

      const parsedChainId = Number(chainId)
      if (!(await validateActiveNetwork(parsedChainId))) {
        throw new Error(`active network does not match desired chain id`)
      }

      console.info(
        'depositing %o tokens into single strategy vault %o',
        amount.toString(),
        strategyAddress
      )

      const data = strategyContract.interface.encodeFunctionData('deposit', [
        fixedToBigNumber(amount),
        account,
      ])

      await depositTransaction({
        chainId: parsedChainId,
        to: strategyAddress,
        from: account,
        data,
      })

      // Wait for state of delete transaction to complete.
      await waitForStatus(
        async () => transactionStates.deposit,
        resetDepositState,
        ['Success', 'Exception', 'Fail'],
        100,
        1000
      )

      trackEvent(
        config?.safary?.vault?.deposit?.type ?? 'click',
        'on vault deposit',
        {
          chainId: chainId.toString(),
          contract: strategyAddress,
          method: 'deposit',
          amount: amount.toString(),
        }
      )

      readFromSubgraph()
    },
    [
      account,
      signer,
      config,
      provider,
      validateActiveNetwork,
      strategyAddress,
      strategyContract.interface,
      depositTransaction,
      trackEvent,
      resetDepositState,
      readFromSubgraph,
      transactionStates.deposit,
    ]
  )

  const withdrawFromVault = useCallback(
    async (chainId: string, amount: FixedNumber, maxLoss?: FixedNumber) => {
      if (!account || !signer || !provider) {
        return
      }

      if (!signer) {
        return
      }

      const parsedChainId = Number(chainId)
      if (!(await validateActiveNetwork(parsedChainId))) {
        throw new Error(`active network does not match desired chain id`)
      }

      console.info(
        'withdrawing %o shares from single strategy vault %o',
        amount.toString(),
        strategyAddress
      )

      let data: string
      if (maxLoss) {
        console.info('maxLoss set to be %o', maxLoss.toString())
        data = strategyContract.interface.encodeFunctionData(
          'redeem(uint256,address,address,uint256)',
          [
            fixedToBigNumber(amount),
            account,
            account,
            fixedToBigNumber(maxLoss),
          ]
        )
      } else {
        data = strategyContract.interface.encodeFunctionData(
          'redeem(uint256,address,address)',
          [fixedToBigNumber(amount), account, account]
        )
      }

      await withdrawTransaction({
        chainId: parsedChainId,
        to: strategyAddress,
        from: account,
        data,
      })

      // Wait for state of delete transaction to complete.
      await waitForStatus(
        async () => transactionStates.withdraw,
        resetWithdrawState,
        ['Success', 'Exception', 'Fail'],
        100,
        1000
      )

      trackEvent(
        config?.safary?.vault?.withdraw?.type ?? 'click',
        'on vault withdraw',
        {
          chainId: chainId.toString(),
          contract: strategyAddress,
          method: 'withdraw',
          amount: amount.toString(),
        }
      )

      readFromSubgraph()
    },
    [
      config,
      account,
      signer,
      provider,
      validateActiveNetwork,
      strategyAddress,
      trackEvent,
      withdrawTransaction,
      resetWithdrawState,
      readFromSubgraph,
      strategyContract.interface,
      transactionStates.withdraw,
    ]
  )

  // #endregion

  // Admin methods for vault governor

  // #region

  const setTimeToMaturityThreshold = useCallback(
    async (chainId: string, timeToMaturityThresholdInDays: number) => {
      if (!account || !signer || !provider) {
        return
      }

      if (!signer) {
        return
      }

      const parsedChainId = Number(chainId)
      if (!(await validateActiveNetwork(parsedChainId))) {
        throw new Error(`active network does not match desired chain id`)
      }

      const timeToMaturityThreshold = timeToMaturityThresholdInDays * 86400

      console.info(
        'updating timeToMaturityThreshold to %o for single strategy vault %o',
        timeToMaturityThreshold,
        strategyAddress
      )

      const targetCallData = strategyContract.interface.encodeFunctionData(
        'setTimeToMaturityThreshold',
        [BigNumber.from(timeToMaturityThreshold)]
      )

      console.log('target call data: %o', targetCallData)

      if (proposerContract && delayModifierContract && isProposer) {
        await sendTransactionToProposerSafe(targetCallData)
      } else {
        await setTimeToMaturityThresholdTransaction({
          chainId: parsedChainId,
          to: strategyAddress,
          from: account,
          data: targetCallData,
        })

        // Wait for state of delete transaction to complete.
        await waitForStatus(
          async () => transactionStates.setTimeToMaturityThreshold,
          resetSetTimeToMaturityThresholdState,
          ['Success', 'Exception', 'Fail'],
          100,
          1000
        )
      }

      readFromSubgraph()
    },
    [
      account,
      proposerContract,
      isProposer,
      provider,
      readFromSubgraph,
      resetSetTimeToMaturityThresholdState,
      setTimeToMaturityThresholdTransaction,
      signer,
      delayModifierContract,
      strategyAddress,
      transactionStates.setTimeToMaturityThreshold,
      validateActiveNetwork,
      strategyContract.interface,
      sendTransactionToProposerSafe,
    ]
  )

  const setRequiredReserveRatio = useCallback(
    async (chainId: string, requiredReserveRatio: FixedNumber) => {
      if (!account || !signer || !provider) {
        return
      }

      if (!signer) {
        return
      }

      const parsedChainId = Number(chainId)
      if (!(await validateActiveNetwork(parsedChainId))) {
        throw new Error(`active network does not match desired chain id`)
      }

      const requiredReserveRatioBN = fixedToBigNumber(requiredReserveRatio)

      console.info(
        'updating requiredReserveRatio to %o for single strategy vault %o',
        requiredReserveRatioBN.toString(),
        strategyAddress
      )

      const targetCallData = strategyContract.interface.encodeFunctionData(
        'setRequiredReserveRatio',
        [requiredReserveRatioBN]
      )

      if (proposerContract && delayModifierContract && isProposer) {
        await sendTransactionToProposerSafe(targetCallData)
      } else {
        await setRequiredReserveRatioTransaction({
          chainId: parsedChainId,
          to: strategyAddress,
          from: account,
          data: targetCallData,
        })

        // Wait for state of delete transaction to complete.
        await waitForStatus(
          async () => transactionStates.setRequiredReserveRatio,
          resetSetRequiredReserveRatioState,
          ['Success', 'Exception', 'Fail'],
          100,
          1000
        )

        readFromSubgraph()
      }
    },
    [
      account,
      isProposer,
      provider,
      readFromSubgraph,
      sendTransactionToProposerSafe,
      delayModifierContract,
      proposerContract,
      resetSetRequiredReserveRatioState,
      setRequiredReserveRatioTransaction,
      signer,
      strategyAddress,
      transactionStates.setRequiredReserveRatio,
      validateActiveNetwork,
      strategyContract.interface,
    ]
  )

  const setRepoTokenConcentrationLimit = useCallback(
    async (chainId: string, repoTokenConcentrationLimit: FixedNumber) => {
      if (!account || !signer || !provider) {
        return
      }

      if (!signer) {
        return
      }

      const parsedChainId = Number(chainId)
      if (!(await validateActiveNetwork(parsedChainId))) {
        throw new Error(`active network does not match desired chain id`)
      }

      const repoTokenConcentrationLimitBN = fixedToBigNumber(
        repoTokenConcentrationLimit
      )

      console.info(
        'updating repoTokenConcentrationLimit to %o for single strategy vault %o',
        repoTokenConcentrationLimitBN.toString(),
        strategyAddress
      )

      const targetCallData = strategyContract.interface.encodeFunctionData(
        'setRepoTokenConcentrationLimit',
        [repoTokenConcentrationLimitBN]
      )

      if (proposerContract && delayModifierContract && isProposer) {
        await sendTransactionToProposerSafe(targetCallData)
      } else {
        await setRepoTokenConcentrationLimitTransaction({
          chainId: parsedChainId,
          to: strategyAddress,
          from: account,
          data: targetCallData,
        })

        // Wait for state of delete transaction to complete.
        await waitForStatus(
          async () => transactionStates.setRepoTokenConcentrationLimit,
          resetSetRepoTokenConcentrationLimitState,
          ['Success', 'Exception', 'Fail'],
          100,
          1000
        )
      }

      readFromSubgraph()
    },
    [
      account,
      signer,
      provider,
      validateActiveNetwork,
      strategyAddress,
      strategyContract.interface,
      proposerContract,
      delayModifierContract,
      isProposer,
      readFromSubgraph,
      sendTransactionToProposerSafe,
      setRepoTokenConcentrationLimitTransaction,
      resetSetRepoTokenConcentrationLimitState,
      transactionStates.setRepoTokenConcentrationLimit,
    ]
  )

  const setCollateralTokenParams = useCallback(
    async (
      chainId: string,
      collateralToken: Address,
      minCollateralRatio: FixedNumber
    ) => {
      if (!account || !signer || !provider) {
        return
      }

      if (!signer) {
        return
      }

      const parsedChainId = Number(chainId)
      if (!(await validateActiveNetwork(parsedChainId))) {
        throw new Error(`active network does not match desired chain id`)
      }

      const minCollateralRatioBN = fixedToBigNumber(minCollateralRatio)

      console.info(
        'updating collateralTokens for single strategy vault %o - setting %o with ratio %o',
        strategyAddress,
        collateralToken,
        minCollateralRatioBN.toString()
      )

      const targetCallData = strategyContract.interface.encodeFunctionData(
        'setCollateralTokenParams',
        [collateralToken, minCollateralRatioBN]
      )

      if (proposerContract && delayModifierContract && isProposer) {
        await sendTransactionToProposerSafe(targetCallData)
      } else {
        await setCollateralTokenParamsTransaction({
          chainId: parsedChainId,
          to: strategyAddress,
          from: account,
          data: targetCallData,
        })

        // Wait for state of delete transaction to complete.
        await waitForStatus(
          async () => transactionStates.setCollateralTokenParams,
          resetSetCollateralTokenParamsState,
          ['Success', 'Exception', 'Fail'],
          100,
          1000
        )
      }

      readFromSubgraph()
    },
    [
      account,
      signer,
      provider,
      validateActiveNetwork,
      strategyAddress,
      strategyContract.interface,
      proposerContract,
      delayModifierContract,
      isProposer,
      readFromSubgraph,
      sendTransactionToProposerSafe,
      setCollateralTokenParamsTransaction,
      resetSetCollateralTokenParamsState,
      transactionStates.setCollateralTokenParams,
    ]
  )

  const setDiscountRateAdapter = useCallback(
    async (chainId: string, discountRateAdapter: Address) => {
      if (!account || !signer || !provider) {
        return
      }

      if (!signer) {
        return
      }

      const parsedChainId = Number(chainId)
      if (!(await validateActiveNetwork(parsedChainId))) {
        throw new Error(`active network does not match desired chain id`)
      }

      console.info(
        'updating single strategy vault %o - setting discount rate adapter to be %o',
        strategyAddress,
        discountRateAdapter
      )

      const targetCallData = strategyContract.interface.encodeFunctionData(
        'setDiscountRateAdapter',
        [discountRateAdapter]
      )

      if (proposerContract && delayModifierContract && isProposer) {
        await sendTransactionToProposerSafe(targetCallData)
      } else {
        await setDiscountRateAdapterTransaction({
          chainId: parsedChainId,
          to: strategyAddress,
          from: account,
          data: targetCallData,
        })

        // Wait for state of delete transaction to complete.
        await waitForStatus(
          async () => transactionStates.setDiscountRateAdapter,
          resetSetDiscountRateAdapterState,
          ['Success', 'Exception', 'Fail'],
          100,
          1000
        )
      }

      readFromSubgraph()
    },
    [
      account,
      signer,
      provider,
      validateActiveNetwork,
      strategyAddress,
      strategyContract.interface,
      proposerContract,
      delayModifierContract,
      isProposer,
      readFromSubgraph,
      sendTransactionToProposerSafe,
      setDiscountRateAdapterTransaction,
      resetSetDiscountRateAdapterState,
      transactionStates.setDiscountRateAdapter,
    ]
  )

  const setDiscountRateMarkup = useCallback(
    async (chainId: string, discountRateMarkup: FixedNumber) => {
      if (!account || !signer || !provider) {
        return
      }

      if (!signer) {
        return
      }

      const parsedChainId = Number(chainId)
      if (!(await validateActiveNetwork(parsedChainId))) {
        throw new Error(`active network does not match desired chain id`)
      }

      console.info(
        'updating single strategy vault %o - setting discount rate markup to be %o',
        strategyAddress,
        discountRateMarkup.toString()
      )

      const targetCallData = strategyContract.interface.encodeFunctionData(
        'setDiscountRateMarkup',
        [fixedToBigNumber(discountRateMarkup)]
      )

      if (proposerContract && delayModifierContract && isProposer) {
        await sendTransactionToProposerSafe(targetCallData)
      } else {
        await setDiscountRateMarkupTransaction({
          chainId: parsedChainId,
          to: strategyAddress,
          from: account,
          data: targetCallData,
        })

        // Wait for state of delete transaction to complete.
        await waitForStatus(
          async () => transactionStates.setDiscountRateMarkup,
          resetSetDiscountRateMarkupState,
          ['Success', 'Exception', 'Fail'],
          100,
          1000
        )
      }

      readFromSubgraph()
    },
    [
      account,
      signer,
      provider,
      validateActiveNetwork,
      strategyAddress,
      strategyContract.interface,
      proposerContract,
      delayModifierContract,
      isProposer,
      readFromSubgraph,
      sendTransactionToProposerSafe,
      setDiscountRateMarkupTransaction,
      resetSetDiscountRateMarkupState,
      transactionStates.setDiscountRateMarkup,
    ]
  )

  const setRepoTokenBlacklist = useCallback(
    async (
      chainId: string,
      repoTokenAddress: Address,
      isBlacklisted: boolean
    ) => {
      if (!account || !signer || !provider) {
        return
      }

      if (!signer) {
        return
      }

      const parsedChainId = Number(chainId)
      if (!(await validateActiveNetwork(parsedChainId))) {
        throw new Error(`active network does not match desired chain id`)
      }

      console.info(
        'updating single strategy vault %o - setting repo token %o to be blacklisted: %o',
        strategyAddress,
        repoTokenAddress,
        isBlacklisted
      )

      const targetCallData = strategyContract.interface.encodeFunctionData(
        'setRepoTokenBlacklist',
        [repoTokenAddress, isBlacklisted]
      )

      if (proposerContract && delayModifierContract && isProposer) {
        await sendTransactionToProposerSafe(targetCallData)
      } else {
        await setRepoTokenBlacklistTransaction({
          chainId: parsedChainId,
          to: strategyAddress,
          from: account,
          data: targetCallData,
        })

        // Wait for state of delete transaction to complete.
        await waitForStatus(
          async () => transactionStates.setRepoTokenBlacklist,
          resetSetRepoTokenBlacklistState,
          ['Success', 'Exception', 'Fail'],
          100,
          1000
        )
      }

      readFromSubgraph()
    },
    [
      account,
      signer,
      provider,
      validateActiveNetwork,
      strategyAddress,
      strategyContract.interface,
      proposerContract,
      delayModifierContract,
      isProposer,
      readFromSubgraph,
      sendTransactionToProposerSafe,
      setRepoTokenBlacklistTransaction,
      resetSetRepoTokenBlacklistState,
      transactionStates.setRepoTokenBlacklist,
    ]
  )

  const setTermController = useCallback(
    async (chainId: string, termController: Address) => {
      if (!account || !signer || !provider) {
        return
      }

      if (!signer) {
        return
      }

      const parsedChainId = Number(chainId)
      if (!(await validateActiveNetwork(parsedChainId))) {
        throw new Error(`active network does not match desired chain id`)
      }

      console.info(
        'updating single strategy vault %o - setting term controller to be %o',
        strategyAddress,
        termController
      )

      const targetCallData = strategyContract.interface.encodeFunctionData(
        'setTermController',
        [termController]
      )

      if (proposerContract && delayModifierContract && isProposer) {
        await sendTransactionToProposerSafe(targetCallData)
      } else {
        await setTermControllerTransaction({
          chainId: parsedChainId,
          to: strategyAddress,
          from: account,
          data: targetCallData,
        })

        // Wait for state of delete transaction to complete.
        await waitForStatus(
          async () => transactionStates.setTermController,
          resetSetTermControllerState,
          ['Success', 'Exception', 'Fail'],
          100,
          1000
        )
      }

      readFromSubgraph()
    },
    [
      account,
      signer,
      provider,
      validateActiveNetwork,
      strategyAddress,
      strategyContract.interface,
      proposerContract,
      delayModifierContract,
      isProposer,
      readFromSubgraph,
      sendTransactionToProposerSafe,
      setTermControllerTransaction,
      resetSetTermControllerState,
      transactionStates.setTermController,
    ]
  )

  const setPerformanceFee = useCallback(
    async (chainId: string, performanceFee: FixedNumber) => {
      if (!account || !signer || !provider) {
        return
      }

      if (!signer) {
        return
      }

      const parsedChainId = Number(chainId)
      if (!(await validateActiveNetwork(parsedChainId))) {
        throw new Error(`active network does not match desired chain id`)
      }

      console.info(
        'updating single strategy vault %o - setting performance fee to be %o',
        strategyAddress,
        performanceFee
      )

      const targetCallData = strategyContract.interface.encodeFunctionData(
        'setPerformanceFee',
        [fixedToBigNumber(performanceFee)]
      )

      if (proposerContract && delayModifierContract && isProposer) {
        await sendTransactionToProposerSafe(targetCallData)
      } else {
        await setPerformanceFeeTransaction({
          chainId: parsedChainId,
          to: strategyAddress,
          from: account,
          data: targetCallData,
        })

        // Wait for state of delete transaction to complete.
        await waitForStatus(
          async () => transactionStates.setPerformanceFee,
          resetSetPerformanceFeeState,
          ['Success', 'Exception', 'Fail'],
          100,
          1000
        )
      }

      readFromSubgraph()
    },
    [
      account,
      signer,
      provider,
      validateActiveNetwork,
      strategyAddress,
      strategyContract.interface,
      proposerContract,
      delayModifierContract,
      isProposer,
      readFromSubgraph,
      sendTransactionToProposerSafe,
      setPerformanceFeeTransaction,
      resetSetPerformanceFeeState,
      transactionStates.setPerformanceFee,
    ]
  )

  const pauseStrategy = useCallback(
    async (chainId: string) => {
      if (!account || !signer || !provider) {
        return
      }

      if (!signer) {
        return
      }

      const parsedChainId = Number(chainId)
      if (!(await validateActiveNetwork(parsedChainId))) {
        throw new Error(`active network does not match desired chain id`)
      }

      console.info('pausing single strategy vault %o', strategyAddress)

      const targetCallData =
        strategyContract.interface.encodeFunctionData('pauseStrategy')

      if (proposerContract && delayModifierContract && isProposer) {
        await sendTransactionToProposerSafe(targetCallData)
      } else {
        await pauseStrategyTransaction({
          chainId: parsedChainId,
          to: strategyAddress,
          from: account,
          data: targetCallData,
        })

        // Wait for state of delete transaction to complete.
        await waitForStatus(
          async () => transactionStates.pauseStrategy,
          resetPauseStrategyState,
          ['Success', 'Exception', 'Fail'],
          100,
          1000
        )
      }

      readFromSubgraph()
    },
    [
      account,
      signer,
      provider,
      validateActiveNetwork,
      strategyAddress,
      strategyContract.interface,
      proposerContract,
      delayModifierContract,
      isProposer,
      readFromSubgraph,
      sendTransactionToProposerSafe,
      pauseStrategyTransaction,
      resetPauseStrategyState,
      transactionStates.pauseStrategy,
    ]
  )

  const unpauseStrategy = useCallback(
    async (chainId: string) => {
      if (!account || !signer || !provider) {
        return
      }

      if (!signer) {
        return
      }

      const parsedChainId = Number(chainId)
      if (!(await validateActiveNetwork(parsedChainId))) {
        throw new Error(`active network does not match desired chain id`)
      }

      console.info('unpausing single strategy vault %o', strategyAddress)

      const targetCallData =
        strategyContract.interface.encodeFunctionData('unpauseStrategy')

      if (proposerContract && delayModifierContract && isProposer) {
        await sendTransactionToProposerSafe(targetCallData)
      } else {
        await unpauseStrategyTransaction({
          chainId: parsedChainId,
          to: strategyAddress,
          from: account,
          data: targetCallData,
        })

        // Wait for state of delete transaction to complete.
        await waitForStatus(
          async () => transactionStates.unpauseStrategy,
          resetUnpauseStrategyState,
          ['Success', 'Exception', 'Fail'],
          100,
          1000
        )
      }

      readFromSubgraph()
    },
    [
      account,
      signer,
      provider,
      validateActiveNetwork,
      strategyAddress,
      strategyContract.interface,
      proposerContract,
      delayModifierContract,
      isProposer,
      readFromSubgraph,
      sendTransactionToProposerSafe,
      unpauseStrategyTransaction,
      resetUnpauseStrategyState,
      transactionStates.unpauseStrategy,
    ]
  )

  const pauseDeposit = useCallback(
    async (chainId: string) => {
      if (!account || !signer || !provider) {
        return
      }

      if (!signer) {
        return
      }

      const parsedChainId = Number(chainId)
      if (!(await validateActiveNetwork(parsedChainId))) {
        throw new Error(`active network does not match desired chain id`)
      }

      console.info(
        'pausing deposit on single strategy vault %o',
        strategyAddress
      )

      const targetCallData =
        strategyContract.interface.encodeFunctionData('pauseDeposit')

      if (proposerContract && delayModifierContract && isProposer) {
        await sendTransactionToProposerSafe(targetCallData)
      } else {
        await pauseDepositTransaction({
          chainId: parsedChainId,
          to: strategyAddress,
          from: account,
          data: targetCallData,
        })

        // Wait for state of delete transaction to complete.
        await waitForStatus(
          async () => transactionStates.pauseDeposit,
          resetPauseDepositState,
          ['Success', 'Exception', 'Fail'],
          100,
          1000
        )
      }

      readFromSubgraph()
    },
    [
      account,
      signer,
      provider,
      validateActiveNetwork,
      strategyAddress,
      strategyContract.interface,
      proposerContract,
      delayModifierContract,
      isProposer,
      readFromSubgraph,
      sendTransactionToProposerSafe,
      pauseDepositTransaction,
      resetPauseDepositState,
      transactionStates.pauseDeposit,
    ]
  )

  const unpauseDeposit = useCallback(
    async (chainId: string) => {
      if (!account || !signer || !provider) {
        return
      }

      if (!signer) {
        return
      }

      const parsedChainId = Number(chainId)
      if (!(await validateActiveNetwork(parsedChainId))) {
        throw new Error(`active network does not match desired chain id`)
      }

      console.info(
        'unpausing deposit on single strategy vault %o',
        strategyAddress
      )

      const targetCallData =
        strategyContract.interface.encodeFunctionData('unpauseDeposit')

      if (proposerContract && delayModifierContract && isProposer) {
        await sendTransactionToProposerSafe(targetCallData)
      } else {
        await unpauseDepositTransaction({
          chainId: parsedChainId,
          to: strategyAddress,
          from: account,
          data: targetCallData,
        })

        // Wait for state of delete transaction to complete.
        await waitForStatus(
          async () => transactionStates.unpauseDeposit,
          resetUnpauseDepositState,
          ['Success', 'Exception', 'Fail'],
          100,
          1000
        )
      }

      readFromSubgraph()
    },
    [
      account,
      signer,
      provider,
      validateActiveNetwork,
      strategyAddress,
      strategyContract.interface,
      proposerContract,
      delayModifierContract,
      isProposer,
      readFromSubgraph,
      sendTransactionToProposerSafe,
      unpauseDepositTransaction,
      resetUnpauseDepositState,
      transactionStates.unpauseDeposit,
    ]
  )

  const shutdownStrategy = useCallback(
    async (chainId: string) => {
      if (!account || !signer || !provider) {
        return
      }

      if (!signer) {
        return
      }

      const parsedChainId = Number(chainId)
      if (!(await validateActiveNetwork(parsedChainId))) {
        throw new Error(`active network does not match desired chain id`)
      }

      console.info('shutting down single strategy vault %o', strategyAddress)

      const targetCallData =
        strategyContract.interface.encodeFunctionData('shutdownStrategy')

      if (proposerContract && delayModifierContract && isProposer) {
        await sendTransactionToProposerSafe(targetCallData)
      } else {
        await shutdownStrategyTransaction({
          chainId: parsedChainId,
          to: strategyAddress,
          from: account,
          data: targetCallData,
        })

        // Wait for state of delete transaction to complete.
        await waitForStatus(
          async () => transactionStates.shutdownStrategy,
          resetShutdownStrategyState,
          ['Success', 'Exception', 'Fail'],
          100,
          1000
        )
      }

      readFromSubgraph()
    },
    [
      account,
      signer,
      provider,
      validateActiveNetwork,
      strategyAddress,
      strategyContract.interface,
      proposerContract,
      delayModifierContract,
      isProposer,
      readFromSubgraph,
      sendTransactionToProposerSafe,
      shutdownStrategyTransaction,
      resetShutdownStrategyState,
      transactionStates.shutdownStrategy,
    ]
  )

  const acceptManagement = useCallback(
    async (chainId: string) => {
      if (!account || !signer || !provider) {
        return
      }

      if (!signer) {
        return
      }

      const parsedChainId = Number(chainId)
      if (!(await validateActiveNetwork(parsedChainId))) {
        throw new Error(`active network does not match desired chain id`)
      }

      console.info(
        'accepting management of single strategy vault %o',
        strategyAddress
      )

      const targetCallData =
        strategyContract.interface.encodeFunctionData('acceptManagement')

      await acceptManagementTransaction({
        chainId: parsedChainId,
        to: strategyAddress,
        from: account,
        data: targetCallData,
      })

      // Wait for state of delete transaction to complete.
      await waitForStatus(
        async () => transactionStates.acceptManagement,
        resetAcceptManagementState,
        ['Success', 'Exception', 'Fail'],
        100,
        1000
      )

      readFromSubgraph()
    },
    [
      account,
      signer,
      provider,
      validateActiveNetwork,
      strategyAddress,
      strategyContract.interface,
      acceptManagementTransaction,
      resetAcceptManagementState,
      readFromSubgraph,
      transactionStates.acceptManagement,
    ]
  )

  // #endregion

  // Vault parameter updates (public functions)

  // #region
  const executeVaultParameterUpdate = useCallback(
    async (chainId: string, queueNonce: number, calldata: string) => {
      if (!account || !signer || !provider) {
        return
      }

      if (queueNonce === undefined || !delayModifierContract) {
        return
      }

      const parsedChainId = Number(chainId)
      if (!(await validateActiveNetwork(parsedChainId))) {
        throw new Error(`active network does not match desired chain id`)
      }

      console.info(
        `executing vault parameter update for single strategy vault: ${strategyAddress} at nonce: ${queueNonce}`
      )
      console.info('using calldata: %o', calldata)

      // Prepare the safe transaction for execution

      console.info('creating execute transaction for delay mod...')

      const data = delayModifierContract.interface.encodeFunctionData(
        'executeNextTx',
        [ethers.utils.getAddress(strategyAddress), 0, calldata, 0]
      )

      await executeVaultParameterUpdateTransaction({
        chainId: parsedChainId,
        to: delayModifierAddress,
        from: account,
        data,
      })

      // Wait for state of delete transaction to complete.
      await waitForStatus(
        async () => transactionStates.executeVaultParameterUpdate,
        resetExecuteVaultParameterUpdateState,
        ['Success', 'Exception', 'Fail'],
        100,
        1000
      )

      readFromSubgraph()
    },
    [
      account,
      signer,
      provider,
      delayModifierContract,
      validateActiveNetwork,
      strategyAddress,
      executeVaultParameterUpdateTransaction,
      delayModifierAddress,
      resetExecuteVaultParameterUpdateState,
      readFromSubgraph,
      transactionStates.executeVaultParameterUpdate,
    ]
  )

  const skipExpiredTransactions = useCallback(
    async (chainId: string) => {
      if (!account || !signer || !provider) {
        return
      }

      if (!delayModifierContract) {
        return
      }

      const parsedChainId = Number(chainId)
      if (!(await validateActiveNetwork(parsedChainId))) {
        throw new Error(`active network does not match desired chain id`)
      }

      console.info(
        `skipping expired transactions on delay mod ${delayModifierContract.address} for single strategy vault: ${strategyAddress}`
      )

      const data =
        delayModifierContract.interface.encodeFunctionData('skipExpired')

      await skipExpiredDelayModifierTransaction({
        chainId: parsedChainId,
        to: delayModifierAddress,
        from: account,
        data,
      })

      // Wait for state of delete transaction to complete.
      await waitForStatus(
        async () => transactionStates.skipExpiredDelayModifier,
        resetSkipExpiredDelayModifierState,
        ['Success', 'Exception', 'Fail'],
        100,
        1000
      )

      readFromSubgraph()
    },
    [
      account,
      signer,
      provider,
      delayModifierContract,
      validateActiveNetwork,
      strategyAddress,
      skipExpiredDelayModifierTransaction,
      delayModifierAddress,
      resetSkipExpiredDelayModifierState,
      readFromSubgraph,
      transactionStates.skipExpiredDelayModifier,
    ]
  )

  return useMemo(
    () => ({
      deposit: depositIntoVault,
      withdraw: withdrawFromVault,
      setTimeToMaturityThreshold,
      setRequiredReserveRatio,
      setRepoTokenConcentrationLimit,
      setCollateralTokenParams,
      setDiscountRateAdapter,
      setDiscountRateMarkup,
      setRepoTokenBlacklist,
      setTermController,
      setPerformanceFee,
      pauseStrategy,
      unpauseStrategy,
      pauseDeposit,
      unpauseDeposit,
      shutdownStrategy,
      acceptManagement,
      executeVaultParameterUpdate,
      skipExpiredTransactions,
    }),
    [
      depositIntoVault,
      withdrawFromVault,
      setTimeToMaturityThreshold,
      setRequiredReserveRatio,
      setRepoTokenConcentrationLimit,
      setCollateralTokenParams,
      setDiscountRateAdapter,
      setDiscountRateMarkup,
      setRepoTokenBlacklist,
      setTermController,
      setPerformanceFee,
      pauseStrategy,
      unpauseStrategy,
      pauseDeposit,
      unpauseDeposit,
      shutdownStrategy,
      acceptManagement,
      executeVaultParameterUpdate,
      skipExpiredTransactions,
    ]
  )
}
