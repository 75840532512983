import {
  Box,
  Divider,
  Link,
  Text,
  Image,
  Flex,
  Button,
  Spinner,
} from '@chakra-ui/react'
import PendingChanges from '../PendingChanges'
import aragon_icon from '../../../../assets/icons/aragon.svg'
import tick_icon from '../../../../assets/icons/tick_blue.svg'
import { HStack } from '../../../../components/elements/Stack'
import { MappedGovernanceProposal } from '../../../../models/vault'
import { useState } from 'react'
import { useTermToast } from '../../../../hooks/toasts'
import { termToastMessages } from '../../../../helpers/toasts'
import { Address } from '../../../../data/model'

type Props = {
  account?: Address
  url: string
  hasExpiredTransactions: boolean
  pendingGovernance: MappedGovernanceProposal[]
  pendingExecution: MappedGovernanceProposal[]
  onExecuteChanges: (nonce: number, calldata: string) => Promise<void>
  onSkipExpiredTransactions: () => Promise<void>
}

export default function VaultGovernance({
  account,
  url,
  pendingGovernance,
  pendingExecution,
  hasExpiredTransactions,
  onExecuteChanges,
  onSkipExpiredTransactions,
}: Props) {
  const hasPendingGovernance = pendingGovernance.length > 0
  const hasPendingExecution = pendingExecution.length > 0
  const hasAnyPendingChanges = hasPendingGovernance || hasPendingExecution

  const termToast = useTermToast()
  const [isExecutingUpdate, setIsExecutingUpdate] = useState(false)

  const handleExecute = async (
    hasExpired: boolean,
    label: string,
    nonce: number,
    calldata: string
  ) => {
    setIsExecutingUpdate(true)

    if (hasExpired) {
      termToast.pending(
        termToastMessages.skipExpiredVaultUpdateTransactions.pending()
      )
      try {
        await onSkipExpiredTransactions()
        termToast.success(
          termToastMessages.skipExpiredVaultUpdateTransactions.success()
        )
      } catch (error) {
        if ((error as Error).message.includes('user rejected transaction')) {
          termToast.dismissed()
        } else {
          termToast.failure(
            termToastMessages.skipExpiredVaultUpdateTransactions.failure()
          )
        }
        // exit early if the skip fails
        return
      }
    }

    termToast.pending(
      termToastMessages.executeVaultParameterUpdate.pending(label)
    )
    try {
      await onExecuteChanges(nonce, calldata)
      termToast.success(
        termToastMessages.executeVaultParameterUpdate.success(label)
      )
    } catch (error) {
      if ((error as Error).message.includes('user rejected transaction')) {
        termToast.dismissed()
      } else {
        termToast.failure(
          termToastMessages.executeVaultParameterUpdate.failure()
        )
      }
    } finally {
      setIsExecutingUpdate(false)
    }
  }

  return (
    <HStack
      alignItems="start"
      justify="space-between"
      bg="blue.0"
      p={8}
      w="full"
      maxW="full"
    >
      <Box w="full">
        <Text variant="body-l/semibold" color="blue.9">
          Pending Changes
        </Text>

        {!hasAnyPendingChanges ? (
          <Text variant="body-xl/normal" color="blue.9" mt={6}>
            There are no pending changes at this time.
          </Text>
        ) : (
          <HStack alignItems="stretch" mt={6} spacing={6}>
            <Box w="50%" display="flex" flexDirection="column">
              {hasPendingGovernance ? (
                <PendingChanges type="governance" changes={pendingGovernance} />
              ) : (
                <Flex flex="1" align="center" justify="center">
                  <Box bg="white" p={5} borderRadius="12px">
                    <Text
                      textAlign="center"
                      variant="body-md/semibold"
                      color="blue.9"
                    >
                      There are no proposals to vote on at this time.
                    </Text>
                  </Box>
                </Flex>
              )}
            </Box>
            <Box w="50%" display="flex" flexDirection="column">
              {hasPendingExecution ? (
                <PendingChanges type="execution" changes={pendingExecution} />
              ) : (
                <Flex flex="1" align="center" justify="center">
                  <Box bg="white" p={5} borderRadius="12px">
                    <Text
                      textAlign="center"
                      variant="body-md/semibold"
                      color="blue.9"
                    >
                      There are no executions pending at this time. <br />
                      Please check back later.
                    </Text>
                  </Box>
                </Flex>
              )}
            </Box>
          </HStack>
        )}

        <Box mt={12}>
          <Divider borderColor="blue.9" mt={16} mb={8} />
          <HStack alignItems="stretch" spacing={6}>
            {/* Aragon dao footer information */}
            <Box
              w="50%"
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
            >
              <Text variant="body-md/normal" color="blue.9">
                Check the status of your proposals and vote on pending changes
              </Text>
              <Box mt={4} display="flex" gap={4} alignItems="center">
                <Box pr={4} borderRight="1px" h="20px" borderColor="gray.5">
                  <Link
                    href={`${url}/governance`}
                    textDecoration="none"
                    color="gray.5"
                    isExternal
                  >
                    Governance Page
                  </Link>
                </Box>
                <Box h="20px">
                  <Link
                    href={`${url}/community`}
                    textDecoration="none"
                    color="gray.5"
                    isExternal
                  >
                    Join the Community and Vote
                  </Link>
                </Box>
                <Box>
                  <Image
                    bg="blue.0"
                    src={aragon_icon}
                    alt="Aragon Logo"
                    w="102px"
                  />
                </Box>
              </Box>
            </Box>

            {/* Proposal update action */}
            {hasPendingExecution && (
              <Box
                w="50%"
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
              >
                <Text variant="body-md/normal" color="blue.9">
                  Confirm and execute pending changes.
                </Text>
                <Box mt={4} display="flex" gap={4} alignItems="center">
                  <Button
                    width="160px"
                    height="32px"
                    size="sm"
                    isDisabled={!account || isExecutingUpdate}
                    rightIcon={
                      isExecutingUpdate ? undefined : (
                        <Image src={tick_icon} alt="Tick Icon" />
                      )
                    }
                    variant="secondary"
                    onClick={() =>
                      handleExecute(
                        hasExpiredTransactions,
                        pendingExecution[0].methodProposed,
                        pendingExecution[0].delay.queueNonce,
                        pendingExecution[0].delay.calldata
                      )
                    }
                  >
                    <Text as="span">Execute changes</Text>
                    {isExecutingUpdate && (
                      <>
                        <Spinner size="sm" ml="2" />{' '}
                      </>
                    )}
                  </Button>
                </Box>
              </Box>
            )}
          </HStack>
        </Box>
      </Box>
    </HStack>
  )
}
