import {
  Box,
  BoxProps,
  Button,
  Skeleton,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react'
import { BigNumber, FixedNumber } from 'ethers'
import { HStack } from '../../../../components/elements/Stack'
import Banner from '../Banner'
import VaultCharts from '../VaultCharts'
import VaultDepositWithdraw from '../VaultDepositWithdraw'
import VaultStats from '../VaultStats'
import {
  gasTokenCurrency,
  purchaseCurrency,
} from '../../../../test-helpers/data-portfolio'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans } from '@lingui/macro'

export default function VaultPageLoading({
  onViewMetaVault,
  onViewVaults,
  metaVaultSelected,
}: {
  onViewMetaVault: () => void
  onViewVaults: () => void
  metaVaultSelected: string | undefined
}) {
  const isDataLoaded = false
  return (
    <>
      <Box maxW="1440px" px={{ base: 16, xl: 20 }} mx="auto" pt={4}>
        <HStack mb={4}>
          <Button
            variant="body-xs/medium"
            height="auto"
            p={0}
            rightIcon={<FontAwesomeIcon icon={['fal', 'angle-right']} />}
            onClick={onViewVaults}
          >
            <Trans>Vaults</Trans>
          </Button>
          {metaVaultSelected && (
            <Button
              variant="body-xs/medium"
              height="auto"
              p={0}
              rightIcon={<FontAwesomeIcon icon={['fal', 'angle-right']} />}
              onClick={onViewMetaVault}
            >
              {metaVaultSelected}
            </Button>
          )}
          <Skeleton h="20px" w="150px" />
        </HStack>
        <Box maxW="full">
          <Banner
            currentYield={FixedNumber.fromString('0', 8)}
            vaultReceiptBalance={FixedNumber.fromString('0', 8)}
            vaultReceiptCurrency={purchaseCurrency}
            pricePerShare={FixedNumber.fromString('0', 8)}
            totalAssetValue={FixedNumber.fromString('0', 8)}
            vaultCurator={''}
            vaultName={''}
            vaultAddress={''}
            chainId={1}
            vaultType={'Yearn V3'}
            vaultAssetCurrency={purchaseCurrency}
            isDataLoaded={isDataLoaded}
          />
        </Box>
        <HStack
          mt={6}
          spacing={6}
          alignItems="start"
          maxW="full"
          overflow="hidden"
        >
          <VaultCard h="285px" w="50%" flexBasis="50%">
            <VaultStats
              vaultCurator=""
              WAL={35}
              WALCap={45}
              liquidityReserve={FixedNumber.from('21.43')}
              largestHolding={FixedNumber.from('14.19')}
              requiredReserve={FixedNumber.from('20')}
              concentrationCap={FixedNumber.from('15')}
              performanceFee={FixedNumber.from('0')}
              managementFee={FixedNumber.from('0.5')}
              isGovernor={false}
              isDataLoaded={isDataLoaded}
              onSetRepoTokenConcentrationLimit={async () => {}}
              onSetRequiredReserveRatio={async () => {}}
              onSetTimeToMaturityThreshold={async () => {}}
            />
          </VaultCard>
          <VaultCard h="285px" w="50%" flexBasis="50%">
            <VaultDepositWithdraw
              isDataLoaded={isDataLoaded}
              isDepositPaused={false}
              convertToAssetsRatio={FixedNumber.fromString('1')}
              vaultAssetCurrency={purchaseCurrency}
              availableDepositLimit={FixedNumber.fromString('0')}
              availableWithdrawLimit={FixedNumber.fromString('0')}
              vaultAssetBalance={FixedNumber.fromString('0')}
              vaultAssetAllowance={BigNumber.from(0)}
              vaultAssetPrice={FixedNumber.fromString('0')}
              vaultReceiptCurrency={purchaseCurrency}
              vaultReceiptBalance={FixedNumber.fromString('0')}
              gasTokenBalance={FixedNumber.fromString('0')}
              gasTokenCurrency={gasTokenCurrency}
              onConnect={() => {}}
              onWrapGasToken={async () => {}}
              onVaultAssetApprove={async () => {}}
              onKytCheck={async () => true}
              onDeposit={async () => {}}
              onWithdraw={async () => {}}
            />
          </VaultCard>
        </HStack>
        <VaultCard w="full" mt="24px">
          <Tabs variant="soft-rounded" colorScheme="green">
            <TabList>
              <Tab isDisabled>Charts</Tab>
              <Tab isDisabled>Holdings</Tab>
              <Tab isDisabled>Collateral</Tab>
              <Tab isDisabled>Governance</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <VaultCharts
                  isActiveTab={false}
                  isDataLoaded={isDataLoaded}
                  idleCapitalUsd={FixedNumber.fromString('0')}
                  holdings={[]}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </VaultCard>
      </Box>
    </>
  )
}

function VaultCard({
  children,
  ...props
}: { children?: React.ReactNode } & BoxProps) {
  return (
    <Box borderRadius={'8px'} p={6} bg="#fff" {...props}>
      {children}
    </Box>
  )
}
