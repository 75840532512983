import { Box, Button, Flex, Grid, HStack, Skeleton } from '@chakra-ui/react'
import RepoCardButton from '../RepoCardButton'
import RepoCardHeading from '../RepoCardHeading'

export default function RepoCardLoading() {
  return (
    <>
      <Flex
        key={''}
        flexDir="column"
        width={{
          base: '622px',
          '1xl': '816px',
          '3xl': '1023px',
        }}
        cursor="pointer"
        h="116px"
      >
        {/* First Row */}
        <Flex
          borderRadius={'6px'}
          justifyContent="space-between"
          flexDir={{
            base: 'row',
          }}
          p={3}
          background={'white'}
          transition="all .25s ease-in-out"
        >
          <Flex
            gap={{
              base: 7,
              '1xl': 12,
            }}
            flexDirection={{
              base: 'row',
            }}
            py={2.5}
          >
            <Box
              h="100%"
              w="130px"
              borderRight={{
                base: '1px solid',
              }}
              borderRightColor={{
                base: 'gray.3',
              }}
              pr={2}
            >
              <RepoCardHeading title="Fixed APY" />
              <HStack>
                <Skeleton h="32px" w="80px" />
              </HStack>
            </Box>
            <Grid
              gridTemplateColumns={{
                base: '60px 90px 60px',
              }}
              gap={{
                base: '38px',
                '1xl': 16,
                '3xl': 36,
              }}
            >
              {/* Maturity*/}
              <Flex
                flexDirection="column"
                alignItems="left"
                verticalAlign="top"
                px={0}
              >
                <RepoCardHeading title="Maturity" />
                <Skeleton h="28px" w="80px" />
              </Flex>
              {/* Earn Limit */}
              <Flex
                flexDirection="column"
                align="start"
                verticalAlign="top"
                px={0}
                pos="relative"
              >
                <RepoCardHeading title="Earn Limit" />
                <HStack>
                  <Skeleton h="28px" w="80px" />
                </HStack>
              </Flex>
              {/* Multiplier */}
              <Flex
                flexDirection="column"
                alignItems="left"
                verticalAlign="top"
                px={0}
              >
                <RepoCardHeading title="Multiplier" mb={2.5} />
                <Skeleton h="28px" w="80px" />
              </Flex>
            </Grid>
          </Flex>

          <Flex
            flexDirection="column"
            textAlign="right"
            justifyContent="center"
            minW={{
              base: 'max-content',
            }}
            px={{
              base: 0,
            }}
          >
            <HStack spacing={1} justifyContent="right" h="22px"></HStack>
            <RepoCardButton
              isActive={false}
              isHovered={false}
              handleClick={() => {}}
            >
              Earn
            </RepoCardButton>
            <Button
              size="xs"
              variant="unstyled"
              alignSelf="flex-end"
              color="blue.5"
              height="auto"
            >
              <HStack justifyContent="flex-end" pr={1}></HStack>
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </>
  )
}
