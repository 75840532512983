import { HStack, Image, Text } from '@chakra-ui/react'
import copy from 'copy-to-clipboard'
import dayjs, { Dayjs } from 'dayjs'
import duration from 'dayjs/plugin/duration'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import { BigNumber, FixedNumber, utils } from 'ethers'
import { parseUnits, commify } from 'ethers/lib/utils'
import copyIcon from '../assets/icons/copy.svg'
import Tooltip from '../components/elements/Tooltip'

import {
  Address,
  Auction,
  Currency,
  MappedYieldResult,
  RolloverAuctionInfo,
  SentioResponse,
  TermBucket,
} from '../data/model'
import {
  BILLION_IN_CENTS,
  BLUECHIP_LIST,
  CORRELATED_ASSETS,
  MATURITY_DATE_FORMAT,
  MAX_TOKEN_SYMBOL_LENGTH,
  MILLION_IN_CENTS,
  STABLECOIN_LIST,
  TERM_REPO_TOKEN_SYMBOL,
  THOUSAND_IN_CENTS,
} from './constants'
import {
  bigToFixedNumber,
  formatFixed,
  fixedToFormattedPercentage,
  fixedToBigNumber,
} from './conversions'
import { divide, evaluate, fixedCompare } from './math'
import { AuctionStatus } from './types'
import { Config } from '../config'
import { components } from '../models/profile-api'
import { CollateralCurrencies } from '../models/vaults'

dayjs.extend(duration)
dayjs.extend(localizedFormat)
dayjs.extend(relativeTime)

export function getAuctionDisplayId({
  auctionEndTimestamp,
  maturityTimestamp,
  termSymbol,
  collateralSymbol,
}: {
  termSymbol: string
  collateralSymbol: string
} & Pick<Auction, 'auctionEndTimestamp' | 'maturityTimestamp'>): string {
  const term = (() => {
    switch (bucketMaturityTimestamp(auctionEndTimestamp, maturityTimestamp)) {
      case 'one':
        return '1W'
      case 'two':
        return '2W'
      case 'four':
        return '4W'
      case 'six':
        return '6W'
      case 'eight':
        return '8W'
      case 'thirteen':
        return '13W'
      case 'twenty-six':
        return '26W'
      default:
        return '52W'
    }
  })()

  const { displayToken } = getDisplayToken(collateralSymbol, {
    skipEllipsis: true,
  })

  const maturity = dayjs.unix(maturityTimestamp).format('MMM D')
  return `${term}-${termSymbol}(${displayToken})-${maturity}`
}

// Returns days and hours left in auction. Omits days if 0.
export function getAuctionTimeLeft(
  maturityTimestamp: number,
  now: Dayjs,
  options?: { compact?: boolean }
) {
  const maturity = dayjs.unix(maturityTimestamp)
  const diff = maturity.diff(now, 'days', true)
  const days = Math.floor(diff)
  const hours = Math.floor((diff - days) * 24)

  if (options?.compact) {
    return `${days > 0 ? `${days} days ` : `${hours} hours`}`
  }
  return `In ${days > 0 ? `${days} days ` : ''} ${hours} hours`
}

export function termLengthToColor(termLength: string) {
  switch (termLength) {
    case '1 week': {
      return '#1128B6'
    }
    case '2 week': {
      return '#001027'
    }
    case '4 week': {
      return '#5C3AD6'
    }
    case '6 week': {
      return '#154E9F'
    }
    case '8 week': {
      return '#026553'
    }
    case '13 week': {
      return '#026992'
    }
    case '26 week': {
      return '#E07F00'
    }
    case '52 week': {
      return '#09F'
    }
    default: {
      return '#1128B6'
    }
  }
}

export function bucketMaturityTimestamp(
  auctionEndTimestamp: number,
  maturityTimestamp: number
): TermBucket {
  const dur = dayjs.duration(
    dayjs.unix(maturityTimestamp).diff(dayjs.unix(auctionEndTimestamp))
  )

  if (dur.asWeeks() <= 1.1) {
    return 'one'
  }
  if (dur.asWeeks() <= 2.1) {
    return 'two'
  }
  if (dur.asWeeks() <= 4.42857) {
    // NOTE: This was changed as a special request to get a 30 day auction to show up as 4 weeks instead of 8 weeks
    return 'four'
  }
  if (dur.asWeeks() <= 6.1) {
    return 'six'
  }
  if (dur.asWeeks() <= 8.1) {
    return 'eight'
  }
  if (dur.asWeeks() <= 13.1) {
    return 'thirteen'
  }
  if (dur.asWeeks() <= 26.1) {
    return 'twenty-six'
  }
  return 'fifty-two'
}
export const shortenAddress = (
  address: string,
  options?: { charsFront?: number; charsBack?: number; charsMiddle?: number }
) => {
  // add two accounting for 0x
  const front = address.substring(0, (options?.charsFront ?? 3) + 2)
  const middle = '.'.repeat(options?.charsMiddle ?? 3)
  const back = address.substring(address.length - (options?.charsBack ?? 3))

  return `${front}${middle}${back}`
}

export function parseNumberInput(str: string, defaultValue: number): number {
  try {
    const parsed = parseFloat(str)
    if (isNaN(parsed)) {
      return defaultValue
    }
    return parsed
  } catch (err) {
    return defaultValue
  }
}

// When a user inputs a string value that represents an amount of currency, we need
// to parse that into a number with a precision commiserate with the the token
// (specified by its "decimals" smart contract method).
export function parseUserInputCurrencyAmount(
  input: string,
  decimals: number,
  defaultInput: string
): BigNumber {
  try {
    return parseUnits(input.replace(',', ''), decimals)
  } catch (err) {
    return parseUnits(defaultInput.replace(',', ''), decimals)
  }
}
export function parseUserInputCurrencyAmountFN(
  input: string,
  decimals: number,
  defaultInput: string
): FixedNumber {
  try {
    return FixedNumber.fromString(
      input.replace(',', ''),
      `fixed128x${decimals}`
    )
  } catch (err) {
    return FixedNumber.fromString(
      defaultInput.replace(',', ''),
      `fixed128x${decimals}`
    )
  }
}

export function getMarginRatio(
  collateral: {
    amount: BigNumber
    tokenDecimals: number
    price: BigNumber
    priceDecimals: number
  },
  loan: {
    amount: BigNumber
    tokenDecimals: number
    price: BigNumber
    priceDecimals: number
  }
): number {
  const fCollateral = FixedNumber.fromValue(
    collateral.amount,
    collateral.tokenDecimals
  )
  const fCollateralPrice = FixedNumber.fromValue(
    collateral.price,
    collateral.priceDecimals
  )

  const fMargin = parseFloat(
    evaluate(
      {
        nodeKind: 'mul',
        args: [
          {
            nodeKind: 'value',
            value: fCollateral,
          },
          {
            nodeKind: 'value',
            value: fCollateralPrice,
          },
        ],
      },
      fCollateralPrice.format.decimals
    ).toString()
  )

  const fLoanAmount = FixedNumber.fromValue(loan.amount, loan.tokenDecimals)
  const fLoanPrice = FixedNumber.fromValue(loan.price, loan.priceDecimals)

  const fLoan = parseFloat(
    evaluate(
      {
        nodeKind: 'mul',
        args: [
          {
            nodeKind: 'value',
            value: fLoanAmount,
          },
          {
            nodeKind: 'value',
            value: fLoanPrice,
          },
        ],
      },
      fLoanPrice.format.decimals
    ).toString()
  )

  return fMargin / fLoan
}

export function getAuctionTermString(
  endDate: number,
  maturityDate: number
): string {
  const bucket = bucketMaturityTimestamp(endDate, maturityDate)
  switch (bucket) {
    case 'one':
      return '1 week'
    case 'two':
      return '2 week'
    case 'four':
      return '4 week'
    case 'six':
      return '6 week'
    case 'eight':
      return '8 week'
    case 'thirteen':
      return '13 week'
    case 'twenty-six':
      return '26 week'
    default:
      return '52 week'
  }
}

export function getAuctionDurationString(
  startTime: number,
  endTime: number
): string {
  const auctionStartTimestamp = dayjs.unix(startTime)
  const auctionRevealTimestamp = dayjs.unix(endTime)
  const diff = auctionStartTimestamp.diff(auctionRevealTimestamp)
  return dayjs.duration(diff).humanize() === 'a day'
    ? '24 hours'
    : dayjs.duration(diff).humanize()
}

export const getAuctionStatus = (
  startDate: number,
  revealDate: number,
  endDate: number,
  now: Dayjs,
  isClosed: boolean
): {
  status: AuctionStatus
  statusTime: string
} => {
  if (isClosed) {
    return {
      status: 'closed',
      statusTime: dayjs.unix(endDate).from(now),
    }
  }
  if (dayjs.unix(revealDate).isBefore(now)) {
    return {
      status: 'clearing',
      statusTime: dayjs.unix(revealDate).to(now, true),
    }
  }
  if (dayjs.unix(startDate).isAfter(now)) {
    return {
      status: 'upcoming',
      statusTime: dayjs.unix(startDate).to(now, true),
    }
  }
  return {
    status: 'live',
    statusTime: dayjs.unix(endDate).to(now, true),
  }
}

export function getCurrency(
  currencies: Currency[],
  address: Address
): Currency | undefined {
  return currencies?.find((c) => c.address === address)
}

export function getCurrencyBySymbol(
  currencies: Currency[],
  address: Address
): Currency | undefined {
  return currencies?.find((c) => c.address === address)
}

export function removeNegativeZero(value: string): string {
  return value === '-$0.00' ? '$0.00' : value
}

export function formatFixedUsd(
  usd: FixedNumber | undefined,
  hideSymbol?: boolean,
  hideTooltip?: boolean
) {
  const [formattedValue, isValueRounded, onlyZeroes] = formatFixed(usd, {
    displayDecimals: 2,
    prefix: hideSymbol ? undefined : '$',
  })

  if (!hideTooltip) {
    return (
      <Tooltip
        noDelay
        hidden={!isValueRounded}
        hasArrow={false}
        label={
          <HStack w="max-content" h="full">
            <Text as="span" variant="body-sm/normal" color="white">
              {usd ? commify(usd.toString()) : ''}
            </Text>
            <Image
              width={3}
              height={3}
              src={copyIcon}
              onClick={() => usd && copy(`${usd?.toString()}`)}
              filter="invert(1)"
              cursor="pointer"
            />
          </HStack>
        }
      >
        {`${onlyZeroes && isValueRounded ? `~` : ``}${formattedValue}`}
      </Tooltip>
    )
  } else {
    return removeNegativeZero(formattedValue)
  }
}

export function getTokenDecimals(symbol: string) {
  switch (symbol.toLowerCase()) {
    case 'usd':
    case 'usdc':
    case 'usdt':
    case 'usde':
    case 'usd0':
    case 'usd0++':
    case 'susde':
    case 'stbt':
    case 'wstbt':
    case 'dai':
    case 'sdai':
    case 'deusd':
    case 'tryb':
    case 'usds':
    case 'susds':
      return 2
    case 'eth':
    case 'weth':
    case 'wsteth':
    case 'cbeth':
    case 'reth':
    case 'rseth':
    case 'weeth':
    case 'crv':
    case 'matic':
    case 'wmatic':
    case 'sepolia':
    case 'apxeth':
    case 'pxeth':
    case 'avax':
    case 'wavax':
    case 'savax':
    case 'ezeth':
    case 'pufeth':
    case 'btrfly':
    case 'sol':
    case 'teth':
    case 'pt-susde-27feb2025':
    case 'pt-susde-27mar2025':
    case 'eth+':
      return 3
    case 'wbtc':
    case 'unibtc':
    case 'tbtc':
    case 'btc.b':
    case 'lbtc':
    case 'cbbtc':
    case TERM_REPO_TOKEN_SYMBOL:
      return 5
    default:
      return 8
  }
}

export function formatFixedToken(
  value: FixedNumber | undefined,
  symbol: string,
  hideSymbol?: boolean,
  hideTooltip?: boolean,
  termTokenSymbol?: string, ///@dev undefined if not a termToken
  isSkipSymbolTruncation?: boolean,
  decimals?: number
) {
  if (symbol.toLowerCase() === 'usd') {
    return formatFixedUsd(value, hideSymbol, hideTooltip)
  }

  const displayDecimals = decimals ?? getTokenDecimals(symbol)

  const { displayToken } = getDisplayToken(symbol, {
    skipTruncation: isSkipSymbolTruncation,
  })

  const [formattedValue, isValueRounded, onlyZeroes] = formatFixed(value, {
    displayDecimals,
    suffix: hideSymbol
      ? undefined
      : termTokenSymbol
        ? ` ${termTokenSymbol}`
        : ` ${displayToken}`,
  })

  if (!hideTooltip) {
    return (
      <Tooltip
        noDelay
        hidden={!isValueRounded}
        hasArrow={false}
        label={
          <HStack w="max-content" h="full">
            <Text as="span" variant="body-sm/normal" color="white">
              {value?.toString()}
            </Text>
            <Image
              width={3}
              height={3}
              src={copyIcon}
              onClick={() => value && copy(`${value?.toString()}`)}
              filter="invert(1)"
              cursor="pointer"
            />
          </HStack>
        }
      >
        <span>
          {`${onlyZeroes && isValueRounded ? `~` : ``}${formattedValue}`}
        </span>
      </Tooltip>
    )
  } else {
    return formattedValue
  }
}

export function formatFixedPercentage(
  percentage: FixedNumber | undefined,
  displayDecimals?: number,
  hideSymbol?: boolean,
  multiplier: FixedNumber | undefined = FixedNumber.fromString(
    '100',
    percentage?.format
  ),
  forceHideTooltip: boolean = false
) {
  const percentageValue = evaluate({
    nodeKind: 'mul',
    args: [
      {
        nodeKind: 'value',
        value: percentage ?? FixedNumber.fromString('0', `fixed128x18`),
      },
      {
        nodeKind: 'value',
        value: multiplier,
      },
    ],
  })

  const { formattedPercentage, isValueRounded } = fixedToFormattedPercentage(
    percentageValue,
    displayDecimals,
    hideSymbol
  )

  const shouldHideTooltip = forceHideTooltip || !isValueRounded

  return (
    <Tooltip
      noDelay
      hidden={shouldHideTooltip}
      hasArrow={false}
      label={
        <HStack w="max-content" h="full">
          <Text as="span" variant="body-sm/normal" color="white">
            {percentageValue?.toString()}%
          </Text>
          <Image
            width={3}
            height={3}
            src={copyIcon}
            onClick={() => percentage && copy(`${percentageValue?.toString()}`)}
            filter="invert(1)"
            cursor="pointer"
          />
        </HStack>
      }
      shouldWrapChildren
    >
      {formattedPercentage}
    </Tooltip>
  )
}

export function formatBigPercentage(percentage: BigNumber | undefined) {
  return formatFixedPercentage(
    percentage ? bigToFixedNumber(percentage, 18) : undefined
  )
}

export function formatBigToken(
  value: BigNumber | undefined,
  decimals: number | undefined,
  symbol: string,
  hideSymbol?: boolean,
  hideToolTip?: boolean
) {
  if (!value || !decimals) {
    return '-'
  }
  return formatFixedToken(
    bigToFixedNumber(value, decimals),
    symbol,
    hideSymbol,
    hideToolTip
  )
}

export const formatAmount = (
  amount: FixedNumber,
  comparator?: BigNumber
): string => {
  if (fixedToBigNumber(amount).lt(THOUSAND_IN_CENTS)) {
    return '< $1.00K'
  }
  if (comparator) {
    if (comparator.gte(BILLION_IN_CENTS)) {
      return `${formatFixedUsd(
        divide(
          amount,
          FixedNumber.fromString('1000000000'),
          amount.format.decimals
        ),
        false,
        true
      )}B`
    } else if (comparator.gte(MILLION_IN_CENTS)) {
      return `${formatFixedUsd(
        divide(
          amount,
          FixedNumber.fromString('1000000'),
          amount.format.decimals
        ),
        false,
        true
      )}M`
    } else if (comparator.gte(THOUSAND_IN_CENTS)) {
      return `${formatFixedUsd(
        divide(amount, FixedNumber.fromString('1000'), amount.format.decimals),
        false,
        true
      )}K`
    }
  }
  return `${formatFixedUsd(amount, false, true)}`
}

export function generateFilterOptions(
  config: Config,
  auctions: Auction[],
  currencies: Currency[],
  currentTime: dayjs.Dayjs
) {
  const tokenAddressToSymbol: { [tokenAddress: string]: string } = {}

  const chainConfig = config.chains

  const tenors: {
    tenorstring: string
    tenorword: TermBucket
    count: number
  }[] = []
  const lends: {
    // token: string
    symbol: string
    count: number
  }[] = []
  const collats: {
    // token: string
    symbol: string
    count: number
  }[] = []
  const statuses: {
    status: AuctionStatus
    count: number
  }[] = []
  const chains: {
    id: string
    name: string
    count: number
  }[] = []

  const lendTracker: { [sidebarItem: string]: number } = {}
  const collatTracker: { [sidebarItem: string]: number } = {}
  const statusTracker: { [sidebarItem: string]: number } = {}
  const loanTermTracker: { [sidebarItem: string]: number } = {}
  const chainsTracker: { [sidebarItem: string]: number } = {}

  for (const curr of currencies ?? []) {
    tokenAddressToSymbol[curr.address] = curr.symbol
  }

  for (const auction of auctions ?? []) {
    const tenor = {
      tenorstring: getAuctionTermString(
        auction.auctionEndTimestamp,
        auction.maturityTimestamp
      ),
      tenorword: bucketMaturityTimestamp(
        auction.auctionEndTimestamp,
        auction.maturityTimestamp
      ),
    }
    const lend = auction.purchaseCurrency
    const collat = auction.collateralCurrency

    const lendSymbol = tokenAddressToSymbol[lend]
    const collatSymbol = tokenAddressToSymbol[collat]

    const chainId = auction.chainId
    const isClearing = dayjs
      .unix(auction.auctionRevealTimestamp)
      .isBefore(currentTime)
    const isOpen = dayjs
      .unix(auction.auctionStartTimestamp)
      .isBefore(currentTime)
    const status: AuctionStatus = auction.closed
      ? 'closed'
      : isClearing
        ? 'clearing'
        : isOpen
          ? 'live'
          : 'upcoming'
    if (!loanTermTracker[tenor.tenorword]) {
      tenors.push({
        ...tenor,
        count: 0, // Filled in later.
      })
      loanTermTracker[tenor.tenorword] = 0
    }
    if (!lendTracker[lendSymbol]) {
      lends.push({
        // token: tokenAddressToSymbol[lend],
        symbol: lendSymbol,
        count: 0, // Filled in later.
      })
      lendTracker[lendSymbol] = 0
    }
    if (!collatTracker[collatSymbol]) {
      collats.push({
        // token: tokenAddressToSymbol[collat],
        symbol: collatSymbol,
        count: 0, // Filled in later.
      })
      collatTracker[collatSymbol] = 0
    }
    if (!statusTracker[status]) {
      statuses.push({
        status,
        count: 0, // Filled in later.
      })
      statusTracker[status] = 0
    }
    if (!chainsTracker[chainId]) {
      chains.push({
        id: chainId,
        name: chainConfig[chainId].chainName,
        count: 0, // Filled in later.
      })
      chainsTracker[chainId] = 0
    }

    lendTracker[lendSymbol] += 1
    collatTracker[collatSymbol] += 1
    statusTracker[status] += 1
    loanTermTracker[tenor.tenorword] += 1
    chainsTracker[chainId] += 1
  }

  // TODO: fix the total counts for currencies - incorrect after switch to using symbols!

  // Fill in count values.
  for (const lend of lends) {
    lend.count = lendTracker[lend.symbol]
  }
  for (const collat of collats) {
    collat.count = collatTracker[collat.symbol]
  }
  for (const status of statuses) {
    status.count = statusTracker[status.status]
  }
  for (const tenor of tenors) {
    tenor.count = loanTermTracker[tenor.tenorword]
  }
  for (const chain of chains) {
    chain.count = chainsTracker[chain.id]
  }

  return {
    tenors,
    lends,
    collats,
    statuses,
    chains,
  }
}

export const getTextColorFromAmount = (
  amount: FixedNumber,
  isNetPosition?: boolean
): string => {
  switch (true) {
    case amount.isNegative():
      return 'red.5'
    case !amount.isNegative() && !amount.isZero():
      return isNetPosition ? 'green.5' : 'blue.9'
    case isEffectivelyZero(amount):
      return 'gray.3'
    default:
      return 'gray.6'
  }
}

export function isEffectivelyZero(number: FixedNumber): boolean {
  const numberBN = fixedToBigNumber(number)
  const positiveThreshold = utils.parseUnits('0.01', 'ether')
  const negativeThreshold = utils.parseUnits('-0.01', 'ether')

  return numberBN.gt(negativeThreshold) && numberBN.lt(positiveThreshold)
}

export const formatMaturityDate = (
  maturityDate: number,
  maturityDateFormat?: string
) => {
  return dayjs
    .unix(maturityDate)
    .format(maturityDateFormat ?? MATURITY_DATE_FORMAT)
}

export function formatAuctionMaturityID(auction: RolloverAuctionInfo) {
  return dayjs.unix(auction.maturityTimestamp).format('MMM DD')
}

export function parseUserInput(
  value: string,
  allowDecimals: boolean = true,
  decimalPlaces: number
) {
  let parsedValue = value
  if (allowDecimals) {
    // Only allow one decimal place with regex and replace
    // all other decimal places with empty string
    parsedValue = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
  } else {
    parsedValue = value.replace(/[^0-9]/g, '')
  }

  const parsedValueDecimals = parsedValue.split('.')[1]?.length || 0

  if (parsedValue.length > 0 && parsedValueDecimals > decimalPlaces) {
    console.warn(
      'truncating user amount input to match provided number of decimals'
    )
    parsedValue = parsedValue.slice(
      0,
      parsedValue.length - (parsedValueDecimals - decimalPlaces)
    )
  }

  if (parsedValue === '.') {
    parsedValue = '0.'
  }

  return parsedValue
}

export function hasHighLTVLabel(
  purchaseCurrency: string,
  collateralCurrency: string,
  maintenanceMarginRatio: FixedNumber
) {
  const maintenanceMarginRatioBN = fixedToBigNumber(maintenanceMarginRatio)
  return (
    ((STABLECOIN_LIST.includes(purchaseCurrency.toLowerCase()) &&
      BLUECHIP_LIST.includes(collateralCurrency.toLowerCase())) ||
      (STABLECOIN_LIST.includes(collateralCurrency.toLowerCase()) &&
        BLUECHIP_LIST.includes(purchaseCurrency.toLowerCase()))) &&
    maintenanceMarginRatioBN.lt(parseUnits('1.25', 18))
  )
}

export function hasEModeLabel(
  purchaseCurrency: string,
  collateralCurrency: string,
  maintenanceMarginRatio: FixedNumber
) {
  const maintenanceMarginRatioBN = fixedToBigNumber(maintenanceMarginRatio)
  const isCorrelated =
    CORRELATED_ASSETS?.[purchaseCurrency.toLowerCase()]?.includes(
      collateralCurrency.toLowerCase()
    ) ?? false
  return isCorrelated && maintenanceMarginRatioBN.lt(parseUnits('1.1', 18))
}

export function hasStableCoinInPair(
  purchaseCurrency: string,
  collateralCurrency: string
) {
  return (
    STABLECOIN_LIST.includes(purchaseCurrency.toLowerCase()) ||
    STABLECOIN_LIST.includes(collateralCurrency.toLowerCase())
  )
}

export const getWeeksBetween = (
  startTimestamp: number,
  endTimestamp: number
): number => {
  const startDate = dayjs.unix(startTimestamp)
  const endDate = dayjs.unix(endTimestamp)
  const duration = dayjs.duration(endDate.diff(startDate))
  const weeks = duration.asWeeks()
  return Math.floor(weeks)
}

export const decodeJWTPayload = (token: string) => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )

  try {
    return JSON.parse(jsonPayload) as {
      iss?: string
      sub?: string
      aud?: string
      exp?: number
      nbf?: number
      iat?: number
      jti?: string
    }
  } catch (e) {
    console.error('Error decoding JWT payload: %o', e)
    return undefined
  }
}

export const isExpiredJWT = (exp: number) => {
  return dayjs().unix() > exp
}

export const constructDiscordAvatarUrl = (
  discordId?: string,
  avatarHash?: string
) => {
  return `https://cdn.discordapp.com/avatars/${discordId}/${avatarHash}.png`
}

export const generateAvgLoanBalanceToolip = (
  currentTime: dayjs.Dayjs,
  season: components['schemas']['Season'] | undefined
) => {
  if (!season) {
    return 'Since the season started.'
  }
  const seasonStart = dayjs(season.start)
  const diffDays = currentTime.diff(seasonStart, 'day')
  return `Over the last ${diffDays} days since ${season.name} started on ${seasonStart.format('MMMM D YYYY')}.`
}

/**
 * Returns the display token symbol and whether it was truncated.
 *
 * @param {string} token - The original token symbol.
 * @param {Object} options - Optional parameters.
 * @param {number} [options.maxLength=MAX_TOKEN_SYMBOL_LENGTH] - Maximum length before truncation.
 * @param {boolean} [options.skipTruncation=false] - If true, skips truncation regardless of length.
 * @returns {Object} - An object containing displayToken and isTruncated.
 */
export const getDisplayToken = (
  token: string,
  options: {
    maxLength?: number
    skipTruncation?: boolean
    skipEllipsis?: boolean
  } = {}
) => {
  const {
    maxLength = MAX_TOKEN_SYMBOL_LENGTH,
    skipTruncation = false,
    skipEllipsis = false,
  } = options

  const isTruncated = !skipTruncation && token.length > maxLength
  const displayToken = isTruncated
    ? token.substring(0, maxLength + 1) + (!skipEllipsis ? '…' : '')
    : token

  return { displayToken, isTruncated }
}

export const mapSentioAPIResults = (
  apiResult: SentioResponse,
  assetDecimalsPerStrategy: {
    [chainId: string]: { [strategyAddress: Address]: number }
  }
) => {
  const mappedResult: {
    [chainId: string]: {
      [strategyAddress: Address]: {
        price: FixedNumber | null | undefined
        timestamp: number
      }
    }
  } = {}

  if (!apiResult.results?.[0]?.matrix?.samples) {
    return mappedResult
  }

  // Loop through all samples
  for (const sample of apiResult.results[0].matrix.samples) {
    const chainId = sample.metric.labels.chain
    const strategyAddress = sample.metric.labels.contract_address.toLowerCase()

    const nonZeroValues = sample.values
      .filter((value) => value?.value && value.value !== 0)
      .sort((a, b) => Number(a.timestamp) - Number(b.timestamp))

    if (nonZeroValues.length === 0) {
      continue
    }

    const earliestNonZeroValue = nonZeroValues[0]

    const priceValue = earliestNonZeroValue.value
    const timestamp = parseInt(earliestNonZeroValue.timestamp, 10)

    if (!priceValue || !timestamp) {
      continue
    }

    if (!mappedResult[chainId]) {
      mappedResult[chainId] = {}
    }

    const decimals = assetDecimalsPerStrategy[chainId]?.[strategyAddress] || 18

    mappedResult[chainId][strategyAddress] = {
      price: bigToFixedNumber(BigNumber.from(priceValue), decimals),
      timestamp,
    }
  }

  return mappedResult
}

export const calculateCurrentYield = (
  currentPricePerShare: {
    [chainId: string]: {
      [strategyAddress: Address]: FixedNumber | null | undefined
    }
  },
  sentioAPIResults: {
    [chainId: string]: {
      [strategyAddress: Address]: {
        price: FixedNumber | null | undefined
        timestamp: number
      }
    }
  }
): MappedYieldResult => {
  const result: MappedYieldResult = {}

  for (const chainId in currentPricePerShare) {
    if (!result[chainId]) {
      result[chainId] = {}
    }

    for (const strategyAddress in currentPricePerShare[chainId]) {
      const currentPrice = currentPricePerShare[chainId][strategyAddress]
      const sentioData = sentioAPIResults?.[chainId]?.[strategyAddress]

      if (
        currentPrice &&
        sentioData &&
        sentioData.price &&
        sentioData.timestamp
      ) {
        try {
          const daysSinceLastUpdate =
            (dayjs().unix() - sentioData.timestamp) / 86400
          const base = !sentioData.price.isZero()
            ? divide(currentPrice, sentioData.price).toUnsafeFloat()
            : 0
          const exponent = Math.round(365.25 / daysSinceLastUpdate)
          const yieldValue = (Math.pow(base, exponent) - 1) * 100
          const yieldFN = FixedNumber.fromString(yieldValue.toFixed(18), 18)
          result[chainId][strategyAddress] = yieldFN

          // console.log(
          //   'current price per share: %o, sentio price: %o, sentio timestamp: %o',
          //   currentPrice.toString(),
          //   sentioData.price.toString(),
          //   dayjs.unix(sentioData.timestamp).format()
          // )
          // console.log('days since last update: %o', daysSinceLastUpdate)
          // console.log('base: %o', base)
          // console.log('exponent: %o', exponent)
          // console.log('yield value: %o', yieldValue)
          // console.log('yield in fixednumber format: %o', yieldFN.toString())
        } catch (e) {
          console.error('Error calculating yield: %o', e)
          result[chainId][strategyAddress] = null
        }
      } else {
        result[chainId][strategyAddress] = null
      }
    }
  }

  return result
}

export const formatWeightedAverageLength = (wal: number) => {
  const secondsInDay = 86400

  const days = Math.floor(wal / secondsInDay)
  // Get the remainder and calculate the fractional part
  const remainder = wal % secondsInDay
  // multiply by 100 to get fraction in decimals
  const fractional = Math.floor((remainder / secondsInDay) * 100)
  const formattedWAL = parseFloat(
    `${days.toString()}.${fractional.toString().padStart(2, '0')}`
  )
  return formattedWAL
}

export const getSortedCollateralSymbols = (
  collateralTokens: CollateralCurrencies[]
) => {
  return collateralTokens
    .sort((a, b) => (fixedCompare(b.ratio, 'gt', a.ratio) ? 1 : -1))
    .map((token) => token.symbol)
}
